import React, { FC, SVGAttributes } from "react";

import * as S from "./Icon.styles";

const DEFAULT_ICON_SIZE = 24;

export type IconProps = SVGAttributes<SVGSVGElement>;

export type WrappingIconProps = Omit<IconProps, "children">;

const Icon: FC<IconProps> = ({
  children,
  role = "img",
  width = DEFAULT_ICON_SIZE,
  height = DEFAULT_ICON_SIZE,
  ...props
}) => (
  <S.Layout
    {...props}
    fill={props.fill || "currentcolor"}
    viewBox="0 0 24 24"
    strokeWidth={0}
    width={width}
    height={height}
    role={role}
    xmlns="http://www.w3.org/2000/svg"
  >
    {children}
  </S.Layout>
);

export default Icon;
