import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import MenuDropdownWrapper from "Components/MenuDropdownWrapper";
import { getUrls } from "Libs/utils";

import MenuLink from "../MenuLink";
import * as S from "../styles";
import UserAvatar from "./UserAvatar";

const UserMenu = ({ user, accountUrl, profile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const layoutRef = useRef(null);

  const formatMessage = useIntl().formatMessage;

  const urls = getUrls({
    accountUrl,
    username: user.username
  });

  const isVendor = process.env.VENDOR_NAME || process.env.VENDOR_URL;

  return (
    <S.Layout ref={layoutRef}>
      <UserAvatar
        layoutRef={layoutRef}
        setIsOpen={setIsOpen}
        user={user}
        isOpen={isOpen}
        profile={profile}
      />

      <MenuDropdownWrapper className="menu-dropdown" isOpen={isOpen}>
        <ul>
          {process.env.CUSTOM_VENDOR_CONSOLE_URL &&
            process.env.CUSTOM_VENDOR_CONSOLE_TITLE && (
              <li>
                <MenuLink
                  label={process.env.CUSTOM_VENDOR_CONSOLE_TITLE}
                  url={process.env.CUSTOM_VENDOR_CONSOLE_URL}
                />
              </li>
            )}
          {!process.env.ENABLE_ORGANIZATION && (
            <>
              <li>
                <MenuLink
                  label={formatMessage({ id: "menu.account" })}
                  url={urls.profileUrl}
                />
              </li>
              {(process.env.ENABLE_BILLING ||
                process.env.CUSTOM_BILLING_URL) && (
                <li>
                  <MenuLink
                    label={formatMessage({ id: "menu.billing" })}
                    url={urls.billingUrl}
                  />
                </li>
              )}
            </>
          )}

          <li>
            <MenuLink
              label={formatMessage({ id: "menu.my_profile" })}
              url={urls.profileUrl}
            />
          </li>

          <li>
            <MenuLink
              label={formatMessage({ id: "menu.support" })}
              url={urls.supportUrl}
            />
          </li>
          <li>
            <MenuLink
              label={formatMessage({ id: "menu.documentation" })}
              target="_blank"
              rel="noopener noreferrer"
              url={urls.docsUrl}
            />
          </li>

          {process.env.ENABLE_ORGANIZATION && !isVendor && (
            <li>
              <S.CreateOrgSeparator isTop />
              <Link to={getUrls().createOrganizationUrl}>
                <S.CreateOrg>
                  <S.Plus />
                  <S.CreateOrgText>
                    {formatMessage({ id: "create_organization" })}
                  </S.CreateOrgText>
                </S.CreateOrg>
              </Link>
              <S.CreateOrgSeparator />
            </li>
          )}
          <li>
            <MenuLink
              label={formatMessage({ id: "menu.logout" })}
              url={urls.logoutUrl}
            />
          </li>
        </ul>
      </MenuDropdownWrapper>
    </S.Layout>
  );
};

UserMenu.propTypes = {
  user: PropTypes.object,
  profile: PropTypes.object,
  accountUrl: PropTypes.string
};

export default UserMenu;
