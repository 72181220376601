//@ts-nocheck
import { Map, fromJS } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  normalize,
  getProjectId,
  getOrganizationDescriptionId,
  getEnvironmentId,
  getEnvironmentDescriptionId,
  isJson,
  getIntegrationDescriptionId
} from "Libs/utils";
import logger from "Libs/logger";
import activityTypes from "Constants/activityTypes";
import { loadEnvironments } from "Reducers/environment";
import { loadDeployment } from "Reducers/environment/deployment";
import { ACTIVITY_CONTEXT } from "Constants/constants";
import {
  LoadProjectActivities,
  Error,
  LoadEnvironmentActivities,
  LoadIntegrationActivities,
  LoadMoreProjectActivities,
  Activity,
  LoadMoreEnvironmentActivities,
  LoadMoreIntegrationActivities,
  UpdateSubscriptionActivity,
  StoreMapStateType,
  State
} from "./types";

/**
 * Get activity's logs
 *
 * @param {object} activity
 * @param {int} retryNumber
 *
 */
export const LOAD_ACTIVITY = "app/activity/load";
export const LOAD_ACTIVITY_FAILURE = "app/activity/load_failure";

export const LOAD_ENVIRONMENT_ACTIVITY = "app/activity/environment/load";

export const LOAD_PROJECT_ACTIVITY = "app/activity/project/load";

export const LOAD_PROJECT_ACTIVITY_FAILURE =
  "app/activity/project/load_failure";

export const LOAD_INTEGRATION_ACTIVITY_FAILURE =
  "app/activity/integration/load_failure";

export const UPDATE_PROJECT_ACTIVITY = "app/activity/project/update";
export const UPDATE_PROJECT_ACTIVITY_SUCCESS =
  "app/activity/project/update_success";
export const UPDATE_PROJECT_ACTIVITY_FAILURE =
  "app/activity/project/update_failure";

export const UPDATE_ENVIRONMENT_ACTIVITY = "app/activity/environment/update";
export const UPDATE_ENVIRONMENT_ACTIVITY_SUCCESS =
  "app/activity/environment/update_sucess";

export const UPDATE_INTEGRATION_ACTIVITY = "app/activity/integration/update";
export const UPDATE_INTEGRATION_ACTIVITY_SUCCESS =
  "app/activity/integration/update_success";

export const ActivityTabName = {
  Recent: "recent",
  All: "all"
};

export const checkDeletedEnvironments = (
  dispatch: any,
  activities: Activity[],
  organizationId: string,
  projectId: string
) => {
  const deletedEnvs = activities.reduce((acc, cu) => {
    if (cu.type === "environment.delete") {
      acc.push(cu.parameters.environment);
    }
    return acc;
  }, []);
  if (deletedEnvs.length) {
    dispatch(
      loadEnvironments({
        organizationId,
        projectId
      })
    );
  }
};

export const loadProjectActivities = createAsyncThunk(
  LOAD_PROJECT_ACTIVITY,
  async (
    {
      projectDescriptionId,
      organizationDescriptionId,
      filterNames,
      context
    }: LoadProjectActivities,
    { dispatch }
  ) => {
    dispatch(setActivityFilterNames({ context, filterNames }));
    const filters = getActivityTypes(filterNames);
    // Don't make any request when no filter is selected
    if (!filters.length) {
      return {
        activities: [],
        hasMore: false,
        organizationDescriptionId,
        projectDescriptionId
      };
    }
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const activities = await client.getProjectActivities(
        projectDescriptionId,
        filters
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }
      checkDeletedEnvironments(
        dispatch,
        activities,
        organizationDescriptionId,
        projectDescriptionId
      );
      return {
        activities,
        hasMore,
        organizationDescriptionId,
        projectDescriptionId
      };
    } catch (error) {
      if (![404, 403, 400].includes((error as Error).code)) {
        logger(error as Error, {
          action: LOAD_PROJECT_ACTIVITY_FAILURE,
          meta: {
            organizationDescriptionId,
            projectDescriptionId
          }
        });
      }

      return {
        type: LOAD_PROJECT_ACTIVITY_FAILURE,
        error: true,
        payload: error
      };
    }
  }
);

export const loadEnvironmentActivities = createAsyncThunk(
  LOAD_ENVIRONMENT_ACTIVITY,
  async (
    {
      projectDescriptionId,
      environmentDescriptionId,
      organizationDescriptionId,
      filterNames,
      context
    }: LoadEnvironmentActivities,
    { dispatch }
  ) => {
    dispatch(setActivityFilterNames({ context, filterNames }));
    const filters = getActivityTypes(filterNames);

    // Don't make any request when no filter is selected
    if (!filters.length) {
      return {
        activities: [],
        hasMore: false,
        organizationDescriptionId,
        environmentDescriptionId,
        projectDescriptionId,
        environmentActivity: []
      };
    }
    try {
      const platformLib = await import("Libs/platform");

      const client = platformLib.default;
      const encodedEnvId = encodeURIComponent(environmentDescriptionId);

      const activities: Activity[] = await client.getEnvironmentActivities(
        projectDescriptionId,
        encodedEnvId,
        filters
      );
      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      checkDeletedEnvironments(
        dispatch,
        activities,
        organizationDescriptionId,
        projectDescriptionId
      );

      const environmentActivity = activities.map(activity => activity.id);

      return {
        activities,
        hasMore,
        organizationDescriptionId,
        environmentDescriptionId,
        projectDescriptionId,
        environmentActivity
      };
    } catch (err) {
      if (![404, 403].includes((err as Error).code)) {
        const errorMessage = isJson(err)
          ? err
          : "An error occurred while attempting to load environment.";
        logger(errorMessage as Error | string, {
          action: "environment_load_activities",
          meta: {
            organizationDescriptionId,
            environmentDescriptionId,
            projectDescriptionId
          }
        });
      }
      return {
        error: true,
        payload: err
      };
    }
  }
);
export const loadIntegrationActivities = createAsyncThunk(
  UPDATE_INTEGRATION_ACTIVITY,
  async (
    {
      organizationDescriptionId,
      projectDescriptionId,
      integrationDescriptionId,

      filterNames,
      context
    }: LoadIntegrationActivities,
    { dispatch }
  ) => {
    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));
    // Don't make any request when no filter is selected
    if (!filters.length) {
      return {
        activities: [],
        hasMore: false,
        organizationDescriptionId,
        projectDescriptionId,
        integrationDescriptionId,
        integrationActivity: []
      };
    }
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const encodedIntegrationId = encodeURIComponent(integrationDescriptionId);

      const activities: Activity[] = await client.getIntegrationActivities(
        projectDescriptionId,
        encodedIntegrationId,
        filters
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      const activitiesForIntegration = activities.map(activity => activity.id);

      return {
        activities,
        hasMore,
        organizationDescriptionId,
        projectDescriptionId,
        integrationDescriptionId,
        integrationActivity: activitiesForIntegration
      };
    } catch (err) {
      if (![404, 403].includes((err as Error).code)) {
        logger(err as Error, {
          action: "integration_load_activities",
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            integrationDescriptionId
          }
        });
      }
      return {
        type: LOAD_INTEGRATION_ACTIVITY_FAILURE,
        error: true,
        payload: err
      };
    }
  }
);

export const loadMoreProjectActivities = createAsyncThunk(
  UPDATE_PROJECT_ACTIVITY,
  async (
    {
      projectDescriptionId,
      organizationDescriptionId,
      filterNames,
      context
    }: LoadMoreProjectActivities,
    { dispatch, getState }
  ) => {
    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));
    const activitiesAlreadyLoaded = getState().activity || Map();
    let activitiesAlreadyLoadedForProject = activitiesAlreadyLoaded.getIn(
      ["byProject", "data", organizationDescriptionId, projectDescriptionId],
      Map()
    );

    let startsAt: boolean | string = false;

    if (filters.length !== 0) {
      activitiesAlreadyLoadedForProject =
        activitiesAlreadyLoadedForProject.filter((activity: Activity) =>
          filters ? filters.indexOf(activity.type) !== -1 : true
        );
    }

    // If we already have activities for this environment
    // We extract the older
    activitiesAlreadyLoadedForProject
      .valueSeq()
      .forEach((activity: Activity) => {
        if (
          !startsAt ||
          new Date(activity.created_at).getTime() <
            new Date(startsAt as string).getTime()
        )
          startsAt = activity.created_at;
      });

    try {
      const project = getState().project.getIn([
        "data",
        organizationDescriptionId,
        projectDescriptionId
      ]);
      const activities = await project.getActivities(filters, startsAt);
      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      // Ensure project level activity is loaded not just environment level activity.

      return {
        type: UPDATE_PROJECT_ACTIVITY_SUCCESS,
        activities,
        hasMore,
        organizationDescriptionId,
        projectDescriptionId,
        filters
      };
    } catch (error) {
      if (![404, 403].includes((error as Error).code)) {
        logger(error as Error, {
          action: UPDATE_PROJECT_ACTIVITY_FAILURE,
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            filters
          }
        });
      }

      return {
        type: UPDATE_PROJECT_ACTIVITY_FAILURE,
        error: true,
        payload: error
      };
    }
  }
);

export const loadMoreEnvironmentActivities = createAsyncThunk(
  UPDATE_ENVIRONMENT_ACTIVITY,
  async (
    {
      projectDescriptionId,
      environmentDescriptionId,
      organizationDescriptionId,
      filterNames,
      context
    }: LoadMoreEnvironmentActivities,
    { dispatch, getState }
  ) => {
    const activitiesAlreadyLoaded =
      getState().activity.get("byEnvironment") || Map();
    let activitiesAlreadyLoadedForProject = activitiesAlreadyLoaded.getIn(
      [
        "data",
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      ],
      []
    );

    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));
    let startsAt: boolean | string = false;

    if (filters.length !== 0) {
      activitiesAlreadyLoadedForProject =
        activitiesAlreadyLoadedForProject.filter((activity: Activity) =>
          filters ? filters.indexOf(activity.type) !== -1 : true
        );
    }

    // If we already have activities for this environment
    // We extract the older
    activitiesAlreadyLoadedForProject.forEach((activity: Activity) => {
      if (
        !startsAt ||
        new Date(activity.created_at).getTime() <
          new Date(startsAt as string).getTime()
      )
        startsAt = activity.created_at;
    });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const environmentId = getEnvironmentId(
        getState,
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      );
      const activities = await client.getEnvironmentActivities(
        getProjectId(getState, projectDescriptionId),
        encodeURIComponent(environmentId),
        filters && filters[0] !== "all_type" ? filters : undefined,
        startsAt
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      return {
        activities,
        hasMore,
        organizationDescriptionId,
        environmentDescriptionId,
        projectDescriptionId,
        filters
      };
    } catch (err) {
      if (![404, 403].includes((err as Error).code)) {
        logger(err as Error, {
          action: UPDATE_ENVIRONMENT_ACTIVITY_SUCCESS,
          meta: {
            organizationDescriptionId,
            environmentDescriptionId,
            projectDescriptionId,
            filters
          }
        });
      }

      return {
        error: true,
        payload: err
      };
    }
  }
);
export const loadMoreIntegrationActivities = createAsyncThunk(
  UPDATE_INTEGRATION_ACTIVITY,
  async (
    {
      organizationDescriptionId,
      projectDescriptionId,
      integrationDescriptionId,
      filterNames,
      context
    }: LoadMoreIntegrationActivities,
    { dispatch, getState }
  ) => {
    const loadedActivities = getState().activity.get("byIntegration") || Map();

    let activitiesAlreadyLoadedForIntegration = loadedActivities.getIn(
      [
        "data",
        organizationDescriptionId,
        projectDescriptionId,
        integrationDescriptionId
      ],
      []
    );
    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));
    let startsAt: boolean | string = false;

    if (filters.length !== 0) {
      activitiesAlreadyLoadedForIntegration =
        activitiesAlreadyLoadedForIntegration.filter((activity: Activity) =>
          filters ? filters.indexOf(activity.type) !== -1 : true
        );
    }

    // If we already have activities for this integration
    // We extract the older
    activitiesAlreadyLoadedForIntegration.forEach((activity: Activity) => {
      if (
        !startsAt ||
        new Date(activity.created_at).getTime() <
          new Date(startsAt as string).getTime()
      )
        startsAt = activity.created_at;
    });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const encodedIntegrationId = encodeURIComponent(integrationDescriptionId);

      const activities = await client.getIntegrationActivities(
        projectDescriptionId,
        encodedIntegrationId,
        filters && filters[0] !== "all_type" ? filters : undefined,
        startsAt
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      return {
        activities,
        hasMore,
        organizationDescriptionId,
        projectDescriptionId,
        integrationDescriptionId,
        filters
      };
    } catch (err) {
      if (![404, 403].includes((err as Error).code)) {
        logger(err as Error, {
          action: UPDATE_INTEGRATION_ACTIVITY_SUCCESS,
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            integrationDescriptionId,
            filters
          }
        });
      }
      return {
        error: true,
        payload: err
      };
    }
  }
);

export const loadActivitySuccess = createAsyncThunk(
  LOAD_ACTIVITY,
  async ({ activity }: { activity: Activity }, { dispatch, getState }) => {
    const { project, environments, state: activityState, type } = activity;

    const projectDescriptionId = project;
    const organizationDescriptionId = getOrganizationDescriptionId(
      getState,
      projectDescriptionId
    );

    const integrationDescriptionId = getIntegrationDescriptionId(
      getState,
      projectDescriptionId
    );
    const reloadDeployment = (environmentId: string) => {
      if (type === "environment.push" && activityState === "complete") {
        dispatch(
          loadDeployment(organizationDescriptionId, project, environmentId, {
            hasRedeployed: true
          })
        );
      }
    };

    try {
      return environments.reduce((acc, environmentId) => {
        const environmentDescriptionId = getEnvironmentDescriptionId(
          getState,
          organizationDescriptionId,
          projectDescriptionId,
          environmentId
        );
        if (!environmentDescriptionId) return acc;

        reloadDeployment(environmentId);
        acc.push({
          activity,
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          integrationDescriptionId
        });
        return acc;
      }, []);
    } catch (error) {
      if (![404, 403, 400].includes((error as Error).code)) {
        const errorMessage = isJson(error)
          ? error
          : "An error occurred while attempting to load activity.";
        logger(errorMessage as Error | string, {
          action: LOAD_ACTIVITY_FAILURE,
          meta: {
            organizationDescriptionId,
            projectDescriptionId
          }
        });
      }
      return { error: true, payload: error };
    }
  }
);

const updateSubscriptionActivity = ({
  state,
  activityPath,
  activity,
  context
}: UpdateSubscriptionActivity) => {
  const filterNames = state.getIn(["filters", context], []);
  const filters = getActivityTypes(filterNames);
  if (filters.length && !filters.includes(activity.type)) return state;
  return state.setIn(activityPath, fromJS(activity));
};

const activity = createSlice({
  name: "activity",
  initialState: Map() as StoreMapStateType,
  reducers: {
    setActivitiesTab(state, action) {
      return state.setIn(["filters", "tab"], action.payload);
    },
    setActivityFilterNames(state, action) {
      return state.setIn(
        ["filters", action.payload.context],
        action.payload.filterNames
      );
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadProjectActivities.pending, state => {
        return state.setIn(["byProject", "loading"], true);
      })
      .addCase(loadProjectActivities.fulfilled, (state, action) => {
        const {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          activities
        } = action.payload as {
          hasMore: boolean;
          organizationDescriptionId: string;
          projectDescriptionId: string;
          activities: Activity[];
        };

        return state
          .setIn(["byProject", "loading"], false)
          .setIn(["byProject", "hasMore"], hasMore)
          .setIn(
            [
              "byProject",
              "data",
              organizationDescriptionId,
              projectDescriptionId
            ],
            fromJS(normalize(activities))
          );
      })
      .addCase(loadProjectActivities.rejected, (state, { payload }) => {
        return state
          .setIn(["byProject", "loading"], false)
          .setIn(["byProject", "loadingMore"], false)
          .setIn(["byEnvironment", "loading"], false)
          .setIn(["byEnvironment", "loadingMore"], false)
          .setIn(["byIntegration", "loading"], false)
          .setIn(["byIntegration", "loadingMore"], false)
          .set("error", payload);
      })
      .addCase(loadEnvironmentActivities.pending, state =>
        state.setIn(["byEnvironment", "loading"], true)
      )
      .addCase(loadEnvironmentActivities.fulfilled, (state, action) => {
        const {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          activities
        } = action.payload;

        return state
          .setIn(["byEnvironment", "loading"], false)
          .setIn(["byEnvironment", "hasMore"], hasMore)
          .setIn(
            [
              "byEnvironment",
              "data",
              organizationDescriptionId,
              projectDescriptionId,
              environmentDescriptionId
            ],
            fromJS(normalize(activities))
          );
      })
      .addCase(loadEnvironmentActivities.rejected, (state, { payload }) => {
        return state
          .setIn(["byProject", "loading"], false)
          .setIn(["byProject", "loadingMore"], false)
          .setIn(["byEnvironment", "loading"], false)
          .setIn(["byEnvironment", "loadingMore"], false)
          .setIn(["byIntegration", "loading"], false)
          .setIn(["byIntegration", "loadingMore"], false)
          .set("error", payload);
      })
      .addCase(loadMoreEnvironmentActivities.pending, state =>
        state.setIn(["byEnvironment", "loading"], true)
      )
      .addCase(loadMoreEnvironmentActivities.fulfilled, (state, action) => {
        const {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          activities
        } = action.payload;
        const currentActivities = state.getIn([
          "byEnvironment",
          "data",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId
        ]);
        const loadedActivities = fromJS(normalize(activities));
        const updatedActivities = currentActivities.merge(loadedActivities);
        return state
          .setIn(["byEnvironment", "loading"], false)
          .setIn(["byEnvironment", "hasMore"], hasMore)
          .setIn(
            [
              "byEnvironment",
              "data",
              organizationDescriptionId,
              projectDescriptionId,
              environmentDescriptionId
            ],
            fromJS(normalize(updatedActivities))
          );
      })
      .addCase(loadMoreEnvironmentActivities.rejected, (state, { payload }) => {
        return state
          .setIn(["byProject", "loading"], false)
          .setIn(["byProject", "loadingMore"], false)
          .setIn(["byEnvironment", "loading"], false)
          .setIn(["byEnvironment", "loadingMore"], false)
          .setIn(["byIntegration", "loading"], false)
          .setIn(["byIntegration", "loadingMore"], false)
          .set("error", payload);
      })
      .addCase(loadMoreProjectActivities.pending, state =>
        state.setIn(["byProject", "loading"], true)
      )
      .addCase(loadMoreProjectActivities.fulfilled, (state, action) => {
        const {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          activities
        } = action.payload;

        const currentActivities =
          state.getIn([
            "byProject",
            "data",
            organizationDescriptionId,
            projectDescriptionId
          ]) || Map();
        const loadedActivities = fromJS(normalize(activities));
        const updatedActivities = currentActivities.merge(loadedActivities);
        return state
          .setIn(["byProject", "loading"], false)
          .setIn(["byProject", "hasMore"], hasMore)
          .setIn(
            [
              "byProject",
              "data",
              organizationDescriptionId,
              projectDescriptionId
            ],
            fromJS(normalize(updatedActivities))
          );
      })
      .addCase(loadMoreProjectActivities.rejected, (state, { payload }) => {
        return state
          .setIn(["byProject", "loading"], false)
          .setIn(["byProject", "loadingMore"], false)
          .setIn(["byEnvironment", "loading"], false)
          .setIn(["byEnvironment", "loadingMore"], false)
          .setIn(["byIntegration", "loading"], false)
          .setIn(["byIntegration", "loadingMore"], false)
          .set("error", payload);
      })
      .addCase(loadMoreIntegrationActivities.pending, state =>
        state.setIn(["byIntegration", "loading"], true)
      )
      .addCase(loadMoreIntegrationActivities.fulfilled, (state, action) => {
        const {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          integrationDescriptionId,
          activities
        } = action.payload;

        const currentActivities =
          state.getIn([
            "byIntegration",
            "data",
            organizationDescriptionId,
            projectDescriptionId,
            integrationDescriptionId
          ]) || Map();
        const loadedActivities = fromJS(normalize(activities));
        const updatedActivities = currentActivities.merge(loadedActivities);
        return state
          .setIn(["byIntegration", "loading"], false)
          .setIn(["byIntegration", "hasMore"], hasMore)
          .setIn(
            [
              "byIntegration",
              "data",
              organizationDescriptionId,
              projectDescriptionId,
              integrationDescriptionId
            ],
            fromJS(normalize(updatedActivities))
          );
      })
      .addCase(loadMoreIntegrationActivities.rejected, (state, { payload }) => {
        return state
          .setIn(["byProject", "loading"], false)
          .setIn(["byProject", "loadingMore"], false)
          .setIn(["byEnvironment", "loading"], false)
          .setIn(["byEnvironment", "loadingMore"], false)
          .setIn(["byIntegration", "loading"], false)
          .setIn(["byIntegration", "loadingMore"], false)
          .set("error", payload);
      })
      .addCase(loadActivitySuccess.pending, state =>
        state
          .setIn(["byEnvironment", "loading"], true)
          .setIn(["byProject", "loading"], true)
          .setIn(["byIntegration", "loading"], true)
      )
      .addCase(loadActivitySuccess.fulfilled, (state, { payload = [] }) => {
        payload.forEach(payload => {
          const {
            organizationDescriptionId,
            projectDescriptionId,
            environmentDescriptionId,
            integrationDescriptionId,
            activity
          } = payload;

          const byProject = updateSubscriptionActivity({
            state,
            activityPath: [
              "byProject",
              "data",
              organizationDescriptionId,
              projectDescriptionId,
              activity.id
            ],
            activity: activity,
            context: ACTIVITY_CONTEXT.Project
          });
          const byEnvironment = updateSubscriptionActivity({
            state: byProject,
            activityPath: [
              "byEnvironment",
              "data",
              organizationDescriptionId,
              projectDescriptionId,
              environmentDescriptionId,
              activity.id
            ],
            activity: activity,
            context: ACTIVITY_CONTEXT.Environment
          });
          state = updateSubscriptionActivity({
            state: byEnvironment,
            activityPath: [
              "byIntegration",
              "data",
              organizationDescriptionId,
              projectDescriptionId,
              integrationDescriptionId,
              activity.id
            ],
            activity: activity,
            context: ACTIVITY_CONTEXT.Integration
          });
        });
        return state
          .setIn(["byProject", "loading"], false)
          .setIn(["byEnvironment", "loading"], false)
          .setIn(["byIntegration", "loading"], false);
      });
  }
});

export const { setActivitiesTab, setActivityFilterNames } = activity.actions;
export default activity.reducer;

export const getActivityTypes = (typeNames: string[]) => {
  let filterTypes: string[] = [];
  if (typeNames.length) {
    filterTypes = typeNames.reduce((activityParams: string[], type) => {
      const param = activityTypes[type];
      return param ? activityParams.concat(param.types) : activityParams;
    }, []);
  }
  return filterTypes;
};

const selectAllProjectActivities = (
  state: State,
  { organizationId, projectId }: { organizationId: string; projectId: string }
) => {
  return state.activity.getIn(
    ["byProject", "data", organizationId, projectId],
    Map()
  );
};

const selectProjectActivitiesByStatus =
  (status: string) => (state: State, params: any) => {
    const allActivities = selectAllProjectActivities(state, params);
    return allActivities.filter(
      (activity: Activity) => activity.state === status
    );
  };
const selectInProgressProjectActivities =
  selectProjectActivitiesByStatus("in_progress");
const selectPendingProjectActivities =
  selectProjectActivitiesByStatus("pending");
const selectCompletedProjectActivities =
  selectProjectActivitiesByStatus("complete");

const selectProjectHasMoreActivities = (state: State) =>
  state.activity.getIn(["byProject", "hasMore"], false);

const selectProjectActivityLoadingState = (state: State) =>
  state.activity?.getIn(["byProject", "loading"], true);

const selectProjectActivityLoadingMoreState = (state: State) =>
  state.activity?.getIn(["byProject", "loadingMore"], false);

const selectAllEnvironmentActivities = (
  state: State,
  {
    organizationId,
    projectId,
    environmentId
  }: { organizationId: string; projectId: string; environmentId: string }
) => {
  return state.activity.getIn(
    ["byEnvironment", "data", organizationId, projectId, environmentId],
    Map()
  );
};
export const getActivityFilters = (state: State, context: string) =>
  state.activity.getIn(["filters", context]);
const selectEnvironmentActivitiesByStatus =
  (status: string) => (state: State, params: any) => {
    const activities = selectAllEnvironmentActivities(state, params);
    return activities.filter((activity: Activity) => activity.state === status);
  };

const selectInProgressEnvironmentActivities =
  selectEnvironmentActivitiesByStatus("in_progress");
const selectPendingEnvironmentActivities =
  selectEnvironmentActivitiesByStatus("pending");
const selectCompletedEnvironmentActivities =
  selectEnvironmentActivitiesByStatus("complete");

const selectEnvironmentActivityLoadingState = (state: State) =>
  state.activity?.getIn(["byEnvironment", "loading"], true);

const selectEnvironmentActivityLoadingMoreState = (state: State) =>
  state.activity?.getIn(["byEnvironment", "loadingMore"], false);

const selectEnvironmentHasMoreActivities = (state: State) =>
  state.activity.getIn(["byEnvironment", "hasMore"], false);

const selectAllIntegrationActivities = (
  state: State,
  {
    organizationId,
    projectId,
    integrationId
  }: { organizationId: string; projectId: string; integrationId: string }
) => {
  return state.activity.getIn(
    ["byIntegration", "data", organizationId, projectId, integrationId],
    Map()
  );
};

const selectIntegrationActivitiesByStatus =
  (status: string) =>
  (
    state: State,
    params: { organizationId: string; projectId: string; integrationId: string }
  ) => {
    const activities = selectAllIntegrationActivities(state, params);
    return activities.filter((activity: Activity) => activity.state === status);
  };
export const getActivityTab = (state: State) =>
  state.activity.getIn(["filters", "tab"], ActivityTabName.Recent);

const selectInProgressIntegrationActivities =
  selectIntegrationActivitiesByStatus("in_progress");
const selectPendingIntegrationActivities =
  selectIntegrationActivitiesByStatus("pending");
const selectCompletedIntegrationActivities =
  selectIntegrationActivitiesByStatus("complete");

const selectIntegrationActivityLoadingState = (state: State) =>
  state.activity?.getIn(["byIntegration", "loading"], true);

const selectIntegrationActivityLoadingMoreState = (state: State) =>
  state.activity?.getIn(["byIntegration", "loadingMore"], false);

const selectIntegrationHasMoreActivities = (state: State) =>
  state.activity.getIn(["byIntegration", "hasMore"], false);

export const getSelectors = (context: string, params: any) => {
  const selectors: Record<string, any> = {
    environment: {
      all: (state: State) => selectAllEnvironmentActivities(state, params),
      inProgress: (state: State) =>
        selectInProgressEnvironmentActivities(state, params),
      pending: (state: State) =>
        selectPendingEnvironmentActivities(state, params),
      completed: (state: State) =>
        selectCompletedEnvironmentActivities(state, params),
      isLoading: (state: State) => selectEnvironmentActivityLoadingState(state),
      isLoadingMore: (state: State) =>
        selectEnvironmentActivityLoadingMoreState(state),
      hasMore: (state: State) => selectEnvironmentHasMoreActivities(state)
    },
    integration: {
      all: (state: State) => selectAllIntegrationActivities(state, params),
      inProgress: (state: State) =>
        selectInProgressIntegrationActivities(state, params),
      pending: (state: State) =>
        selectPendingIntegrationActivities(state, params),
      completed: (state: State) =>
        selectCompletedIntegrationActivities(state, params),
      isLoading: (state: State) => selectIntegrationActivityLoadingState(state),
      isLoadingMore: (state: State) =>
        selectIntegrationActivityLoadingMoreState(state),
      hasMore: (state: State) => selectIntegrationHasMoreActivities(state)
    },
    project: {
      all: (state: State) => selectAllProjectActivities(state, params),
      inProgress: (state: State) =>
        selectInProgressProjectActivities(state, params),
      pending: (state: State) => selectPendingProjectActivities(state, params),
      completed: (state: State) =>
        selectCompletedProjectActivities(state, params),
      isLoading: (state: State) => selectProjectActivityLoadingState(state),
      isLoadingMore: (state: State) =>
        selectProjectActivityLoadingMoreState(state),
      hasMore: (state: State) => selectProjectHasMoreActivities(state)
    }
  };

  // context integration like 'integration.gitlab'
  const kind = context.startsWith("integration") ? "integration" : context;
  return selectors[kind] || selectors["project"];
};
