import React, { FC, ReactNode } from "react";

import Warning from "ds/Icons/Warning";
import ArtyBitsIcon from "ds/Icons/ArtyBitsIcon";

import * as S from "./Banner.styles";
import { css, ICON } from "Libs/themes";

export enum Priority {
  critical = "critical",
  high = "high",
  information = "information"
}

export type Props = {
  priority: keyof typeof Priority;
  text: ReactNode;
  closeBanner: ReactNode;
};

const Banner: FC<Props> = ({
  priority = Priority.information,
  text,
  closeBanner
}) => {
  return (
    <S.Layout priority={priority}>
      {priority === Priority.information ? (
        <ArtyBitsIcon />
      ) : (
        <Warning
          fill={`${
            priority === "critical"
              ?"fill:var(--icon-snow-fill,var(--icon-snow,var(--snow)))":"fill:var(--icon-ebony-fill,var(--icon-ebony,var(--ebony)))"
          }`}
        />
      )}
      <S.TextContainer>{text}</S.TextContainer>
      {priority === Priority.information && <ArtyBitsIcon version="v2" />}
      <S.ButtonContainer>{closeBanner}</S.ButtonContainer>
    </S.Layout>
  );
};

export default Banner;
