import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const VendorLogoContainer = styled.div`
  img {
    width: ${props =>
      props.width
        ? isNaN(props.width)
          ? props.width
          : `${props.width}px`
        : "fit-content"};
    height: ${props =>
      props.height
        ? isNaN(props.height)
          ? props.height
          : `${props.height}px`
        : "35px"};
  }
`;

const VendorIcon = ({ width, path, height }) => (
  <VendorLogoContainer width={width} height={height}>
    <img src={path} alt="logo" />
  </VendorLogoContainer>
);

VendorIcon.propTypes = {
  path: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default VendorIcon;
