import React, { FC } from "react";
import Normal from "../Icons/Warning";
import Information from "../Icons/Info";
import Low from "../Icons/SuccessIcon";
import Critical from "../Icons/Error";

import * as S from "./Sticker.styles";
import { Variant, Priority, Props } from "./Sticker.types";
import { ICON, css } from "Libs/themes";

const getIcon = (priority: keyof typeof Priority) => {
  switch (priority) {
    case Priority.low:
      return <Low fill={"fill:var(--icon-java-fill,var(--icon-java,var(--java)))"} />;
    case Priority.critical:
      return <Critical fill={"fill:var(--icon-red-fill,var(--icon-red,var(--red)))"} />;
    case Priority.normal:
      return <Normal fill={"fill:var(--icon-gold-fill,var(--icon-gold,var(--gold)))"} />;
    case Priority.information:
    default:
      return <Information fill={"fill:var(--icon-skye-fill,var(--icon-skye,var(--skye)))"} />;
  }
};

const Sticker: FC<Props> = ({
  variant = Variant.short,
  priority = Priority.information,
  children,
  text,
  button,
  closeIcon,
  link,
  hasIcon = true,
  ...props
}) => {
  return (
    <S.Layout variant={variant} priority={priority} {...props}>
      {hasIcon && getIcon(priority)}
      <S.TextContainer>
        {text}
        {link}
      </S.TextContainer>
      {(variant === Variant.button || variant === Variant.long) && (
        <S.ButtonContainer>{button}</S.ButtonContainer>
      )}
      {variant === Variant.long && (
        <S.ButtonContainer>{closeIcon}</S.ButtonContainer>
      )}
      {children}
    </S.Layout>
  );
};

export default Sticker;
