import styled from "styled-components";
import { css, BUTTON } from "Libs/themes";

import LiveBtn from "Components/LiveButton";
import { SettingsIcon as BaseSettingsIcon } from "@platformsh/ui-kit";

export const LiveButton = styled(LiveBtn)`
  margin: 0 5px 0 auto;
`;

export const SettingsIconContainer = styled.button`
  cursor: pointer;
  height: 32px;
  width: 32px;
  margin-right: 8px;
  margin-left: 12px;
  line-height: 46px;
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: 2px;

  &:hover {
    background-color:var(--button-slate-background-color,var(--button-slate,var(--slate)));

    & svg {
      fill:var(--button-snow-fill,var(--button-snow,var(--snow)));
    }
  }
`;

export const SettingsIcon = styled(BaseSettingsIcon)`
  fill:var(--button-snow-fill,var(--button-snow,var(--snow)));
`;

export const NavigationRightItemsContainer = styled.div`
  display: flex;
`;
