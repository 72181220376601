import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Map } from "immutable";

import Button from "ds/Button";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import useDecodedParams from "Hooks/useDecodedParams";
import { environmentSelector } from "Reducers/environment";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { subscriptionSelector } from "Reducers/subscription";
import { checkGoLive, getSubscriptionEditUrl } from "Libs/utils";

import { Live } from "./GoLiveButton.styles";

const GoLiveButton = () => {
  const intl = useIntl();
  const environment = useSelectorWithUrlParams(environmentSelector);
  const { organizationId, projectId } = useDecodedParams();

  const project = useSelector(({ project }) =>
    project?.getIn(["data", organizationId, projectId], Map())
  );

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  const subscription = useSelector(state => {
    return subscriptionSelector(state, {
      organizationId,
      projectId,
      id: project?.subscription_id
    });
  });

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription }),
    [project, subscription]
  );

  const goLive = useMemo(
    () => checkGoLive(project, organization),
    [project, organization]
  );

  const showGoLive = environment.is_main && goLive;

  const isVendor = !!(process.env.VENDOR_NAME || process.env.VENDOR_URL);
  const disableGoLive =
    isVendor &&
    String(process.env.ENABLE_BILLING) === "false" &&
    !process.env.CUSTOM_SUBSCRIPTION_UPGRADE_URL;

  if (disableGoLive) return null;

  if (!showGoLive || goLive === "no-permission") {
    return null;
  }

  if (goLive === "live") {
    return <Live>{intl.formatMessage({ id: "live" })}</Live>;
  }

  return goLive === "development" ? (
    <Button
      to={subscriptionEditUrl.url}
      external={subscriptionEditUrl.external}
      blank={subscriptionEditUrl.external}
      css={{ paddingLeft: "16px", paddingRight: "16px" }}
    >
      {intl.formatMessage({ id: "button_go_live" })}
    </Button>
  ) : (
    <Button
      to={`/${organizationId}/${projectId}/-/settings/domains`}
      text={intl.formatMessage({ id: "button_go_live" })}
      css={{ paddingLeft: "16px", paddingRight: "16px" }}
    >
      {intl.formatMessage({ id: "button_go_live" })}
    </Button>
  );
};

export default GoLiveButton;
