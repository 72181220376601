import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Immutable from "immutable";

import SecondaryLink from "./SecondaryLink";
import Chevron from "ds/Icons/Chevron";

import { Props as ButtonProps } from "./Button";

export type Props = {
  from: string;
  to: string;
} & ButtonProps;

/**
 * Requires `Intl`, `ReactRouter` and `Redux`
 */
const BackLink: FC<Props> = ({ from, to, ...props }) => {
  const { location } = useHistory<{ from: string; url: string }>();

  const route = useSelector<{ app: Immutable.Map<string, unknown> }>(
    state => state.app?.getIn(["routeHistory", "previousRoutes", 0]) || to
  ) as string;

  const fromPage = location.state?.from || from;

  return (
    <SecondaryLink to={location.state?.url || route} {...props}>
      <Chevron aria-hidden="true" />
      <FormattedMessage id="back" />{" "}
      {fromPage && <FormattedMessage id={`back.${fromPage}`} />}
    </SecondaryLink>
  );
};

export default BackLink;
