import React, { FC } from "react";

type ArtyBitsProps = {
  version?: "v1" | "v2";
};

const ArtyBitsIcon: FC<ArtyBitsProps> = ({ version = "v1", ...props }) => {
  if (version === "v2") {
    return (
      <svg
        {...props}
        width="76"
        height="40"
        viewBox="0 0 76 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Artsy Bits Right</title>
        <path
          d="M61 18.9998L62.3506 22.6493L66 23.9998L62.3506 25.3504L61 28.9998L59.6494 25.3504L56 23.9998L59.6494 22.6493L61 18.9998Z"
          fill="#FFBDBB"
        />
        <path
          d="M37 9.99985L39.1609 15.8389L45 17.9998L39.1609 20.1608L37 25.9998L34.8391 20.1608L29 17.9998L34.8391 15.8389L37 9.99985Z"
          fill="#6D144F"
        />
        <circle
          r="1.5"
          transform="matrix(1 -6.79279e-08 -1.12513e-07 -1 73.5 12.4998)"
          fill="#6D144F"
        />
        <circle
          r="1.5"
          transform="matrix(1 -6.79279e-08 -1.12513e-07 -1 18.5 27.4998)"
          fill="#6D144F"
        />
        <circle
          r="1.5"
          transform="matrix(1 -6.79279e-08 -1.12513e-07 -1 2.5 12.4998)"
          fill="#FFBDBB"
        />
      </svg>
    );
  }

  return (
    <svg
      {...props}
      width="85"
      height="40"
      viewBox="0 0 85 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Artsy Bits Left</title>

      <path
        d="M44 7.99997L45.3506 11.6494L49 13L45.3506 14.3505L44 18L42.6494 14.3505L39 13L42.6494 11.6494L44 7.99997Z"
        fill="#6D144F"
      />
      <path
        d="M24 18L26.1609 23.8391L32 26L26.1609 28.1609L24 34L21.8391 28.1609L16 26L21.8391 23.8391L24 18Z"
        fill="#FFBDBB"
      />
      <circle
        r="1.5"
        transform="matrix(1 -6.79282e-08 -1.12512e-07 -1 51.5 27.5)"
        fill="#FFBDBB"
      />
      <circle
        r="1.5"
        transform="matrix(1 -6.79282e-08 -1.12512e-07 -1 82.5 18.5)"
        fill="#FFBDBB"
      />
      <circle
        r="1.5"
        transform="matrix(1 -6.79282e-08 -1.12512e-07 -1 2.5 17.5)"
        fill="#6D144F"
      />
    </svg>
  );
};

export default ArtyBitsIcon;
