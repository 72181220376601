import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomScroll from "react-custom-scroll";
import { LiveMessage } from "react-aria-live";
import { useIntl } from "react-intl";

import { sortBy } from "Libs/utils";

import useDecodedParams from "Hooks/useDecodedParams";
import useLocalForage from "Hooks/useLocalForage";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import {
  environmentsSelector,
  environmentTreeSelector
} from "Reducers/environment";

import { EnvironmentsTreeSkeleton, List, NavBar, Nodes } from "./";

import { getAllEnvironments, getTreeData } from "./utils";
import { loadSettings, persistSettings } from "./persist-settings";

import * as S from "./EnvironmentTree.styles";

const EnvironmentTree = () => {
  const intl = useIntl();
  const ref = useRef();
  const scrollEvent = new Event("scrolled");

  const { organizationId, projectId } = useDecodedParams();
  const [listLayout = false, setListLayout, , isLoadingLayout] = useLocalForage(
    "projects_environment-list_layout"
  );

  const [sort, setSort] = useState({ type: "title", order: "asc" });
  const [showEnvs, setShowEnvs] = useState({ active: true, inactive: true });
  const [titleFilter, setTitleFilter] = useState("");

  const envTree = useSelectorWithUrlParams(environmentTreeSelector)?.toArray();
  const envList = useSelectorWithUrlParams(environmentsSelector)
    ?.toArray()
    .map(environment => ({ ...environment.data }));

  useEffect(() => {
    let isCanceled = false;
    const setDefaultSettings = async () => {
      const settings = await loadSettings(projectId);
      if (isCanceled) return;

      if (settings.sort) setSort(settings.sort);
      if (settings.showEnvs) setShowEnvs(settings.showEnvs);
    };

    setDefaultSettings();

    return () => (isCanceled = true);
  }, [projectId]);

  useEffect(() => {
    const filters = {
      showEnvs,
      sort
    };
    Object.keys(filters).forEach(
      key => filters[key] === undefined && delete filters[key]
    );
    persistSettings(projectId, filters);
  }, [showEnvs, sort]);

  const [treeData = [], environments = []] = useMemo(() => {
    if (!envList) return [];
    const rootEnv = envList.filter(env => !env.parent);

    const data = getTreeData(rootEnv, envList);
    return [data, getAllEnvironments(data)];
  }, [envList]);

  const filteredEnvs = useMemo(() => {
    if (!listLayout) return [];
    let list = sortBy(environments, sort.type)
      .filter(
        env =>
          (showEnvs.active && env.status !== "inactive") ||
          (showEnvs.inactive && env.status === "inactive")
      )
      .filter(env =>
        titleFilter
          ? env.title.toLowerCase().includes(titleFilter.toLowerCase())
          : true
      );
    if (sort.order === "desc") list = list.reverse();
    return list;
  }, [environments, listLayout, showEnvs, sort, titleFilter]);

  const visibleNodes = useMemo(() => {
    if (listLayout) return false;

    let list = envTree || [];
    if (titleFilter) {
      list = list.filter(env =>
        env.get("id").toLowerCase().includes(titleFilter.toLowerCase())
      );
    }
    return list.reduce((acc, cu) => {
      acc = [
        ...new Set(acc.concat(cu.get("path")?.toArray() || [], cu.get("id")))
      ];
      return acc;
    }, []);
  }, [envTree?.length, listLayout, titleFilter]);

  const countEnv = arr =>
    arr.reduce((acc, { children = [] }) => {
      acc++;
      if (children.length) acc += countEnv(children);
      return acc;
    }, 0);

  const listEnvironments = listLayout ? filteredEnvs : treeData;

  const toggleSort = (e, type) => {
    e?.preventDefault();
    setSort({
      type,
      order: sort.type === type && sort.order === "asc" ? "desc" : "asc"
    });
  };

  return (
    <S.Wrapper aria-labelledby="environments-heading">
      <S.Header>
        <S.Title id="environments-heading">
          {intl.formatMessage({ id: "environments" })}
        </S.Title>

        {!isLoadingLayout && (
          <NavBar
            setShowEnvs={setShowEnvs}
            setTitleFilter={setTitleFilter}
            setTreeLayout={v => setListLayout(!v)}
            showEnvs={showEnvs}
            sortType={sort.type}
            titleFilter={titleFilter}
            toggleSort={toggleSort}
            treeLayout={!listLayout}
          />
        )}
      </S.Header>

      {!envList ? (
        <EnvironmentsTreeSkeleton />
      ) : (
        <S.Layout ref={ref}>
          <LiveMessage
            message={`displaying environments as a ${
              listLayout ? "list" : "tree"
            }`}
            aria-live="polite"
          />
          <LiveMessage
            message={`inactive environments ${
              showEnvs.inactive ? "visible" : "hidden"
            }`}
            aria-live="polite"
          />
          <LiveMessage
            message={`active environments ${
              showEnvs.active ? "visible" : "hidden"
            }`}
            aria-live="polite"
          />
          {titleFilter && (
            <LiveMessage
              message={`environment list has changed based on search: ${titleFilter}`}
              aria-live="polite"
            />
          )}

          {listLayout ? (
            <List
              environments={listEnvironments}
              sort={sort}
              titleFilter={titleFilter}
              toggleSort={toggleSort}
            />
          ) : (
            <CustomScroll
              {...(countEnv(listEnvironments) > 14 && {
                heightRelativeToParent: "735px"
              })}
              onScroll={() => document.dispatchEvent(scrollEvent)}
              allowOuterScroll
            >
              <S.TreeWrapper>
                <LiveMessage
                  message="displaying environments as a tree"
                  aria-live="polite"
                />
                <Nodes
                  nodes={listEnvironments}
                  organizationId={organizationId}
                  showEnvs={showEnvs}
                  titleFilter={titleFilter}
                  visibleNodes={visibleNodes}
                />
              </S.TreeWrapper>
            </CustomScroll>
          )}
        </S.Layout>
      )}
    </S.Wrapper>
  );
};

export default EnvironmentTree;
