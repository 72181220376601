import localForage from "localforage";
import { fromJS, Map, List } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { entities } from "Libs/platform";
import { isJson, getOrganizationId, normalize } from "Libs/utils";

export const loadSubscriptions = createAsyncThunk(
  "app/subscriptions",
  async (
    { organizationId: organizationDescriptionId, search = {} },
    { getState }
  ) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const organizationId = getOrganizationId(
      getState,
      organizationDescriptionId
    );

    if (!organizationId) {
      throw new Error(
        `Unable to load the organization ${organizationDescriptionId}`
      );
    }

    const results = await client.getOrganizationSubscriptions(organizationId, {
      filter: {
        status: {
          value: ["active", "suspended"],
          operator: "IN"
        }
      },
      ...search
    });

    return results;
  }
);

export const loadNextSubscriptionsPage = createAsyncThunk(
  "app/subscriptions/next",
  async ({ organizationId: organizationDescriptionId }, { getState }) => {
    const linkManager = getState().organizationSubscription.getIn([
      "links",
      organizationDescriptionId
    ]);

    const result = await linkManager.next();

    return result;
  }
);

export const loadSubscription = createAsyncThunk(
  "app/subscription",
  async ({ organizationId, id }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const subscription = await client.getOrganizationSubscription(
      getOrganizationId(getState, organizationId),
      id
    );
    const query = new URLSearchParams(window.location.search);
    if (query.get("trialProjectCreated")) {
      const newDate = new Date(query.get("trialProjectCreated"));
      subscription.data.created_at = newDate.toISOString();
    }

    return subscription;
  }
);

export const updateSubscription = createAsyncThunk(
  "app/subscriptions/update",
  async ({ updates, subscription }, { rejectWithValue }) => {
    try {
      const response = await subscription.update({ ...updates });
      const updatedSubscription = new entities.OrganizationSubscription(
        response.data
      );
      return updatedSubscription;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "app/subscriptions/delete",
  async ({ settings = {}, subscription }) => {
    const { redirect } = settings;

    await subscription.delete();

    const deletedProjectIds =
      (await localForage.getItem("deletedProjectIds")) || [];
    const updatedDeletedProjectIds = deletedProjectIds.concat([
      subscription.project_id
    ]);
    await localForage.setItem("deletedProjectIds", updatedDeletedProjectIds);

    if (redirect) {
      window.location.replace(`${window.location.origin}${redirect}`);
    }

    return subscription;
  }
);

const setError = (state, action) => {
  let message = action.error.message;
  if (isJson(action.error.message)) {
    const errors = JSON.parse(action.error.message);
    message = errors.detail;
    if (errors?.detail?.errors?.length) message = errors.detail.errors[0];
  }

  return state
    .set("errors", message)
    .set("loadingList", false)
    .set("loading", false);
};

const subscriptions = createSlice({
  name: "organizationSubscription",
  initialState: Map({ data: new Map(), loading: "idle" }),
  extraReducers: {
    [loadSubscriptions.pending]: state =>
      state.set("loadingList", true).delete("subscriptionsErrors"),
    [loadNextSubscriptionsPage.pending]: state =>
      state.set("loadingNextList", true),
    [loadSubscription.pending]: state => state.set("loading", true),
    [updateSubscription.pending]: state => state.set("loading", true),
    [deleteSubscription.pending]: state => state.set("loading", true),
    [loadNextSubscriptionsPage.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return state
        .setIn(
          ["data", organizationId],
          state
            .getIn(["data", organizationId])
            .merge(fromJS(normalize(action?.payload?.items)))
        )
        .setIn(
          ["list", organizationId],
          fromJS([
            ...state.getIn(["list", organizationId]).toJS(),
            ...action?.payload?.items?.map(i => i.id)
          ])
        )
        .setIn(
          ["links", organizationId],
          fromJS(action.payload.getLinksManager())
        )
        .set("loadingNextList", false)
        .set("status", "idle");
    },
    [loadSubscriptions.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return state
        .setIn(
          ["data", organizationId],
          fromJS(normalize(action?.payload?.items))
        )
        .setIn(
          ["list", organizationId],
          fromJS(action?.payload?.items?.map(i => i.id))
        )
        .setIn(
          ["links", organizationId],
          fromJS(action.payload.getLinksManager())
        )
        .set("loadingList", false)
        .set("status", "idle");
    },
    [loadSubscription.fulfilled]: (state, action) => {
      return state
        .setIn(
          ["data", action.meta?.arg?.organizationId, action?.payload?.id],
          fromJS(action.payload)
        )
        .set("loading", false)
        .setIn(
          ["projectIdBySubscriptionId", action?.payload?.id],
          action?.payload?.project_id
        );
    },
    [updateSubscription.fulfilled]: (state, action) =>
      state
        .setIn(
          ["data", action.meta?.arg?.organizationId, action?.payload?.id],
          fromJS(action.payload)
        )
        .set("loading", false)
        .delete("errors"),
    [deleteSubscription.fulfilled]: (state, action) =>
      state
        .set("deleted", action.payload)
        .set("loading", false)
        .removeIn([
          "data",
          action.meta?.arg?.organizationId,
          action?.payload?.project_id
        ]),
    [loadSubscriptions.rejected]: (state, action) =>
      state.setIn(
        ["subscriptionsErrors", action.meta?.arg?.organizationId],
        action.error
      ),
    [loadSubscription.rejected]: (state, action) => setError(state, action),
    [loadNextSubscriptionsPage.rejected]: (state, action) =>
      setError(state, action),
    [updateSubscription.rejected]: (state, action) => {
      return state.set("errors", action.payload).set("loading", false);
    },
    [deleteSubscription.rejected]: () => location.replace(location.origin)
  }
});

export default subscriptions.reducer;

export const subscriptionsSelector = (state, props) =>
  state.organizationSubscription
    .getIn(["list", props.organizationId], List())
    .map(id => {
      return subscriptionSelector(state, {
        organizationId: props.organizationId,
        subscriptionId: id.toString()
      });
    });

export const subscriptionSelector = (state, props) =>
  state.organizationSubscription.getIn(
    ["data", props.organizationId, props.subscriptionId],
    Map()
  );

export const loadingListSelector = state =>
  state.organizationSubscription.get("loadingList", false);

export const loadingNextListSelector = state =>
  state.organizationSubscription.get("loadingNextList", false);

export const loadingSelector = state =>
  state.organizationSubscription.get("loading", false);

export const errorsSelector = state =>
  state.organizationSubscription.get("errors", false);

export const subscriptionsLoadingErrorsSelector = (state, props) =>
  state.organizationSubscription.getIn(
    ["subscriptionsErrors", props.organizationId],
    false
  );

export const subscriptionLinksManagerSelector = (state, props) =>
  state.organizationSubscription.getIn(["links", props.organizationId]);
