import { useMemo } from "react";
import { useParams } from "react-router-dom";

/**
 * Extracts the console properties from current component route
 */
const useDecodedParams = <
  Params extends { [K in keyof Params]?: string } = {}
>() => {
  const params = useParams<Params>();

  const decodedParams = useMemo(() => {
    const entries = Object.entries<string | undefined>(params);
    const decodedParams = entries.reduce<Params>((params, [key, value]) => {
      return Object.assign(params, {
        [key]: value ? decodeURIComponent(value) : value
      });
    }, {} as never);
    return decodedParams;
  }, [params]);

  return decodedParams;
};

export default useDecodedParams;
