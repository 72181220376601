import { Map } from "immutable";
import { getUrls } from "Libs/utils";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import OrganizationMenu from "./desktop/OrganizationMenu";
import UserMenu from "./desktop/UserMenu";
import MobileMenu from "./mobile";

const Menu = ({
  user,
  accountUrl,
  breadcrumbs,
  organizations,
  organizationId
}) => {
  const organization = organizations?.get(organizationId);

  const urls = getUrls({
    accountUrl,
    username: user.username,
    organizationName: organization?.name
  });

  const profile = useSelector(({ profile }) =>
    profile?.getIn(["data", user.username], Map())
  )?.toJS();

  return (
    <>
      <MobileMenu breadcrumbs={breadcrumbs} user={user} urls={urls} />
      {organization ? (
        <OrganizationMenu
          user={user}
          accountUrl={accountUrl}
          breadcrumbs={breadcrumbs}
          organizations={organizations}
          organizationId={organizationId}
          profile={profile}
        />
      ) : (
        <UserMenu profile={profile} user={user} accountUrl={accountUrl} />
      )}
    </>
  );
};

Menu.propTypes = {
  user: PropTypes.object,
  organizations: PropTypes.object,
  accountUrl: PropTypes.string,
  organizationId: PropTypes.string,
  breadcrumbs: PropTypes.node
};

export default Menu;
