import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { fromJS, Map } from "immutable";

import { getOrganizationId } from "Libs/utils";
import { MapStoreStateType, StoreMapStateType } from "Reducers/types";

import type OrganizationRegion from "platformsh-client/types/model/OrganizationRegion";

import {
  OrganizationRegionAction,
  SelectorParams,
  SetOrganizationTokenAction
} from "./types";

export const getOrganizationRegions = createAsyncThunk(
  "app/organization/region",
  async ({ organizationId }: { organizationId: string }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const result = await client.getOrganizationRegions(
      getOrganizationId(getState, organizationId),
      {}
    );

    return {
      regions: result?.items
    };
  }
);

const setError = (
  state: StoreMapStateType,
  action: SetOrganizationTokenAction
) => state.set("errors", action.error.message).set("loading", false);

const organizationRegion = createSlice({
  name: "organizationRegion",
  initialState: Map({
    data: Map(),
    loading: "idle"
  }) as StoreMapStateType,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrganizationRegions.pending, (state: StoreMapStateType) =>
        state.set("loading", true)
      )
      .addCase(
        getOrganizationRegions.fulfilled,
        (state: StoreMapStateType, action: OrganizationRegionAction) => {
          const { organizationId } = action.meta.arg;
          const regions = action.payload.regions;

          return state
            .setIn(
              ["data", organizationId],
              fromJS(
                regions.reduce(
                  (
                    acc: { [key: string]: OrganizationRegion },
                    region: OrganizationRegion
                  ) => {
                    acc[region.id] = region;
                    return acc;
                  },
                  {}
                )
              )
            )
            .setIn(
              ["byZone", organizationId],
              fromJS(
                regions
                  .filter(
                    (region: OrganizationRegion) => region.available == true // Double equals because some available keys are strings, and some aren't.
                  )
                  .reduce(
                    (
                      accumulator: { [key: string]: OrganizationRegion[] },
                      current: OrganizationRegion
                    ) => {
                      if (!accumulator[current.zone]) {
                        accumulator[current.zone] = [current];
                        return accumulator;
                      }

                      accumulator[current.zone].push(current);
                      return accumulator;
                    },
                    {}
                  )
              )
            )
            .deleteIn(["errors", organizationId])
            .set("loading", false);
        }
      )
      .addCase(
        getOrganizationRegions.rejected,
        (state: StoreMapStateType, action: SetOrganizationTokenAction) =>
          setError(state, action)
      );
  }
});

export default organizationRegion.reducer;

const selectSelf = (state: MapStoreStateType) => {
  return state.organizationRegion;
};
const getParams = (_: unknown, params: SelectorParams) => params;

export const organizationRegionsByZoneSelector = createSelector(
  selectSelf,
  getParams,
  (organizationRegion, { organizationId }) =>
    organizationRegion?.getIn(["byZone", organizationId], Map())
);

export const organizationRegionSelector = createSelector(
  selectSelf,
  getParams,
  (organizationRegion, { organizationId, regionId }) =>
    organizationRegion?.getIn(["data", organizationId, regionId], Map())
);
