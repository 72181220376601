import styled from "styled-components";
import { css, LINK, getCSSVarString, SECTION, MENU } from "Libs/themes";

export default styled.nav`
  position: absolute;
  background-color:var(--menu-snow-background-color,var(--menu-snow,var(--snow)));
  display: ${props => (props.isOpen ? "inline-block" : "none")};
  left: 24px;
  top: 100%;
  margin-top: 4px;
  font-size: 12px;
  z-index: 10;
  box-shadow: var(--menu-box-shadow-box-shadow,var(--menu-box-shadow,var(--box-shadow)))
    var(--menu-ebony-shadow-box-shadow-color,var(--menu-ebony-shadow,var(--ebony-shadow)));
  border-radius: 2px;
  min-width: 300px;
  width: calc(100vw - 48px);
  ul,
  ol {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  .dropdown-links {
    max-height: 332px;
    .rcs-inner-container,
    .no-scroll {
      padding: 8px;
      box-sizing: border-box;
    }
  }
  a {
    color:var(--link-night-color,var(--link-night,var(--night)));
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    span {
      color:var(--link-night-color,var(--link-night,var(--night)));
      font-size: 14px;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover {
      background-color:var(--link-skye-light-background-color,var(--link-skye-light,var(--skye-light)));
      border-radius: 4px;
    }
    &:focus {
      outline: none;
      border: 1px solid #1664ff;
      border-radius: 4px;
      box-shadow: 0 0 2px #1664ff;
      margin: -1px;
    }
    &.active,
    &:active {
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0;
      span {
        color:var(--link-skye-color,var(--link-skye,var(--skye)));
      }
    }
  }
  .show-all {
    color:var(--section-skye-color,var(--section-skye,var(--skye)));
    border-top: 1px solid
      var(--section-skye-light-border-color,var(--section-skye-light,var(--skye-light)));
    line-height: 65px;
    margin-top: 15px;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    position: absolute;
    width: ${props => props.width || "auto"};
    max-height: 388px;
    left: 0;
  }
`;
