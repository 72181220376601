import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fromJS, Map, List } from "immutable";
import localForage from "localforage";
import moment from "moment";

import client, { request } from "Libs/platform";
import logger from "Libs/logger";
import { getGitTemplateName, isJson } from "Libs/utils";
import config from "Constants/api_config";

import getDefaultBlankWizardSteps from "Constants/wizard/blankSetup";
import getDefaultTemplateWizardSteps from "Constants/wizard/templateSetup";

import { subscriptionSelector } from "Reducers/subscription";

const checkWizard = async () => {
  const wizardStatus = (await localForage.getItem("wizardStatus")) || {};
  return fromJS(wizardStatus);
};

const setWizard = async (organizationId, projectId, status) => {
  const wizardStatus = (await localForage.getItem("wizardStatus")) || {};
  const wizardStatusByProject = fromJS(wizardStatus);

  const newWizard = wizardStatusByProject.setIn(
    [organizationId, projectId, "isOpen"],
    status
  );
  localForage.setItem("wizardStatus", newWizard.toJS());
};

const getTemplateSteps = async repository => {
  const template = getGitTemplateName(repository);
  if (!template) return [];
  const result = await request(
    `${config.api_url}/platform/setup/wizard`,
    "POST",
    {
      template
    }
  ).catch(err => logger(err));
  return result.steps;
};

export const loadRegistry = createAsyncThunk(
  "app/wizard/registry",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const registry = await client.getSetupRegistry();
      dispatch(loadConfig({ registry }));
      return registry;
    } catch (err) {
      const errorMessage = isJson(err)
        ? err
        : "An error occurred accessing setup/registry ";

      logger(errorMessage, {
        action: "setup_registry"
      });
      return rejectWithValue({ errors: errorMessage });
    }
  }
);

export const loadConfig = createAsyncThunk(
  "app/wizard/config",
  async ({ registry }) => {
    const getSetupConfig = async service => {
      let config;
      try {
        config = await client.getSetupConfig({ service });
      } catch (err) {
        const errorMessage = isJson(err)
          ? JSON.parse(err)
          : `An error occurred accessing setup/config: ${service}`;

        logger(errorMessage, {
          action: "setup_config"
        });
      }
      return config;
    };

    let configItems = Map();
    const keys = Object.keys(registry);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const config = await getSetupConfig(key);
      if (config) {
        configItems = configItems.set(key, config);
      }
    }

    return configItems;
  }
);

export const replaceWithCustomeAppName = wizardSteps => {
  const customName = process.env.CUSTOM_APP_NAME;
  if (!customName) return wizardSteps;

  const PSH = /([^./][\s]*)(Platform\.sh|platformsh):?(\.|)/gi;
  return wizardSteps.map(step => {
    step.bodyText = step?.bodyText?.replaceAll(PSH, `$1${customName}$3`);
    step.label = step.label?.replaceAll(PSH, `$1${customName}$3`);
    step.title = step.title?.replaceAll(PSH, `$1${customName}$3`);
    step.copyCode = step.copyCode?.map(({ label, code }) => ({
      code,
      label: label?.replaceAll(PSH, `$1${customName}$3`)
    }));
    return step;
  });
};

export const loadProjectWizard = createAsyncThunk(
  "app/project/wizard/load",
  async ({ user, organizationId, projectId, noCode }, { getState, extra }) => {
    const forceOpenProjectWizard = getState().projectWizard.getIn([
      "config",
      "forceOpenProjectWizard"
    ]);

    let completedIds = {};

    if (user.ssh_keys && user.ssh_keys.length !== 0) {
      completedIds.sshKey = true;
    }

    const project = getState().project?.getIn([
      "data",
      organizationId,
      projectId
    ]);

    const activities = getState().activity?.getIn([
      "byProject",
      "data",
      organizationId,
      projectId
    ]);

    const deployment = getState().deployment?.getIn([
      "data",
      organizationId,
      projectId
    ]);

    const currentDeployment = deployment?.getIn([
      project?.default_branch || "master",
      "current"
    ]);

    const webApps = currentDeployment?.get("webapps");
    if (webApps) {
      completedIds.application = true;
    }

    const services = currentDeployment?.get("services");
    if (services) {
      completedIds.services = true;
    }

    const routes = currentDeployment?.get("routes");
    if (routes) {
      completedIds.routes = true;
    }

    if (webApps && services && routes) {
      completedIds.yaml = true;
    }

    const pushActivity = activities?.filter(item => {
      const type = item.type;
      return type.includes("environment.push");
    });

    const initializeActivity = activities?.filter(item => {
      const type = item.type;
      return type.includes("environment.initialize");
    });

    if (pushActivity?.size > 0) {
      completedIds.push = true;
      completedIds.remote = true;
    } else {
      const projectPushActivities = await project
        ?.getActivities(["environment.push"])
        .catch(err => logger(err));
      if (projectPushActivities?.length > 0) {
        completedIds.push = true;
        completedIds.remote = true;
      }
    }
    let wizardSteps = getDefaultBlankWizardSteps(extra.getIntl());

    if (initializeActivity?.size > 0) {
      const subscription = subscriptionSelector(getState(), {
        organizationId,
        projectId,
        id: project.subscription_id
      });

      const customSteps = await getTemplateSteps(
        subscription?.project_options?.initialize?.repository
      );
      if (customSteps?.length) {
        wizardSteps = customSteps;
      } else {
        wizardSteps = getDefaultTemplateWizardSteps(extra.getIntl());
        completedIds.configure = true;
      }
    }
    const today = new Date();
    const daysSinceCreated = moment(today).diff(
      project?.data.created_at,
      "days"
    );

    // The user already clicked on the button but the loading was not done
    let isOpen = getState().projectWizard.getIn([
      "data",
      organizationId,
      projectId,
      "isOpen"
    ]);

    // If the opening status of the wizard is not yet managed by Redux,
    // test if the status is present on the local storage
    // or force the opening if the project is new.
    if (typeof isOpen === "undefined") {
      const wizardStatus = await checkWizard();
      isOpen = wizardStatus.getIn(
        [organizationId, projectId, "isOpen"],
        noCode ||
          user.ssh_keys.length === 0 ||
          (project && daysSinceCreated < 7)
      );
    }

    return {
      wizardSteps: replaceWithCustomeAppName(wizardSteps),
      completedIds,
      isOpen: isOpen || forceOpenProjectWizard
    };
  }
);

export const toggleProjectWizard = createAsyncThunk(
  "app/project/wizard/toggle",
  async ({ organizationId, projectId }, { getState }) => {
    const isOpen = getState().projectWizard.getIn(
      ["data", organizationId, projectId, "isOpen"],
      false
    );
    const newState = !isOpen;
    setWizard(organizationId, projectId, newState);
    return newState;
  }
);

export const openProjectWizard = createAsyncThunk(
  "app/project/wizard/open",
  async ({ organizationId, projectId }, { getState }) => {
    const isOpen = getState().projectWizard.getIn(
      ["data", organizationId, projectId, "isOpen"],
      false
    );
    if (!isOpen) {
      setWizard(organizationId, projectId, true);
      return false;
    }
    return true;
  }
);

const wizard = createSlice({
  name: "app/project/wizard",
  initialState: Map(),
  reducers: {
    setForceOpen: (state, { payload }) =>
      state.setIn(["config", "forceOpenProjectWizard"], payload),
    nextWizardStep: (state, action) => {
      const { organizationId, projectId } = action.payload;
      const wizardState = state.getIn(
        ["data", organizationId, projectId],
        Map()
      );
      const currentStep = wizardState.get("currentStep", 0);
      const nextStep = currentStep + 1;
      const completedIds = wizardState.get("completed", List()).toJS();

      return state.setIn(
        ["data", organizationId, projectId],
        state
          .getIn(["data", organizationId, projectId], Map())
          .set("currentStep", nextStep)
          .set("start", false)
          .set("finish", wizardState.get("data")?.size < nextStep)
          .set("completed", fromJS(completedIds))
      );
    },
    prevWizardStep: (state, action) => {
      const { organizationId, projectId } = action.payload;
      const wizardState = state.getIn(
        ["data", organizationId, projectId],
        Map()
      );
      const currentStep = wizardState.get("currentStep", 0);
      const prevStep = currentStep - 1;

      return state.setIn(
        ["data", organizationId, projectId],
        state
          .getIn(["data", organizationId, projectId], Map())
          .set("currentStep", prevStep)
          .set("start", prevStep === 0)
          .set("finish", false)
      );
    },
    goToWizardStep: (state, action) => {
      const { organizationId, projectId, step } = action.payload;
      return state.setIn(
        ["data", organizationId, projectId],
        state
          .getIn(["data", organizationId, projectId], Map())
          .set("currentStep", step)
          .set("start", false)
          .set("finish", false)
      );
    }
  },
  extraReducers: {
    [loadProjectWizard.pending]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      return state.setIn(
        ["data", organizationId, projectId],
        state
          .getIn(["data", organizationId, projectId], Map())
          .set("loading", true)
          .set("start", false)
      );
    },
    [loadProjectWizard.fulfilled]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      return state.setIn(
        ["data", organizationId, projectId],
        state
          .getIn(["data", organizationId, projectId], Map())
          .set("loading", false)
          .set("data", fromJS(action.payload.wizardSteps))
          .set("currentStep", 0)
          .set("completed", fromJS(action.payload.completedIds))
          .set("isOpen", action.payload.isOpen)
          .set("start", true)
          .set("finish", false)
          .set("errors", false)
      );
    },
    [loadProjectWizard.rejected]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      return state.setIn(
        ["data", organizationId, projectId],
        state
          .getIn(["data", organizationId, projectId], Map())
          .set("loading", false)
          .set("errors", action.payload)
      );
    },

    // TOGGLE WIZARD
    [toggleProjectWizard.fulfilled]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      return state.setIn(
        ["data", organizationId, projectId],
        state
          .getIn(["data", organizationId, projectId], Map())
          .set("isOpen", action.payload)
          .set("start", true)
          .set("finish", false)
          .set("currentStep", 0)
      );
    },

    // OPEN WIZARD
    [openProjectWizard.pending]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      return state.setIn(
        ["data", organizationId, projectId],
        state
          .getIn(["data", organizationId, projectId], Map())
          .delete("animate")
      );
    },
    [openProjectWizard.fulfilled]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      let wizardState = state
        .getIn(["data", organizationId, projectId], Map())
        .set("isOpen", true)
        .set("animate", action.payload);

      if (!action.payload) {
        wizardState = wizardState
          .set("start", true)
          .set("finish", false)
          .set("currentStep", 0);
      }

      return state.setIn(["data", organizationId, projectId], wizardState);
    },

    // REGISTRY
    [loadRegistry.pending]: state => state.setIn(["registry", "loading"], true),
    [loadRegistry.fulfilled]: (state, action) => {
      return state
        .setIn(["registry", "data"], fromJS(action.payload))
        .setIn(["registry", "loading"], false);
    },
    [loadRegistry.rejected]: (state, action) =>
      state
        .setIn(["registry", "errors"], action.error)
        .setIn(["registry", "loading"], false),

    // CONFIG
    [loadConfig.pending]: state => state.setIn(["config", "loading"], true),
    [loadConfig.fulfilled]: (state, action) =>
      state
        .setIn(["config", "data"], action.payload)
        .setIn(["config", "loading"], false),
    [loadConfig.rejected]: (state, action) =>
      state
        .setIn(["config", "errors"], action.payload)
        .setIn(["config", "loading"], false)
  }
});

export const { setForceOpen, nextWizardStep, prevWizardStep, goToWizardStep } =
  wizard.actions;
export default wizard.reducer;

export const projectWizardSelector = (
  { projectWizard },
  { organizationId, projectId }
) => projectWizard?.getIn(["data", organizationId, projectId], Map()).toJS();

export const configDataSelector = ({ projectWizard }) =>
  projectWizard?.getIn(["config", "data"])?.toJS();

export const configLoadingSelector = ({ projectWizard }) =>
  projectWizard?.getIn(["config", "loading"], false);

export const registryDataSelector = ({ projectWizard }) =>
  projectWizard?.getIn(["registry", "data"])?.toJS();

export const registryLoadingSelector = ({ projectWizard }) =>
  projectWizard?.getIn(["registry", "loading"], false);
