import { BANNER, getCSSVarString, ICON, SECTION } from "Libs/themes";
import styled from "styled-components";

import { Props } from "./Banner";

type LayoutProps = Required<Pick<Props, "priority">>;

const PRIORITIES = {
  information: {
    color:"var(--section-wine-color,var(--section-wine,var(--wine)))",
    background:"var(--banner-misty-rose-background-color,var(--banner-misty-rose,var(--misty-rose)))"
  },
  high: {
    color:"var(--section-ebony-color,var(--section-ebony,var(--ebony)))",
    background:"var(--banner-gold-background-color,var(--banner-gold,var(--gold)))"
  },
  critical: {
    color:"var(--section-snow-color,var(--section-snow,var(--snow)))",
    background:"var(--banner-red-background-color,var(--banner-red,var(--red)))"
  }
};

export const Layout = styled.div<LayoutProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: relative;

  height: 40px;
  padding-right: 8px;
  padding-left: 40px;

  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  background: ${({ priority }) => PRIORITIES[priority].background};
  color: ${({ priority }) => PRIORITIES[priority].color};

  a {
    color: inherit;
    font-weight: 600;
    :after {
      background-color: var(
        --banner-${({ priority }) => priority}-foreground,
        #fff
      );
    }
  }
`;

export const TextContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: 14px;

  & svg path {
    fill: var(--icon-ebony-fill,var(--icon-ebony,var(--ebony)));
  }
  &:hover svg path {
    fill: var(--icon-night-fill,var(--icon-night,var(--night)));
  }
`;
