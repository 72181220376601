import React, { FC } from "react";
import { StyledComponentProps } from "styled-components";
import { Link, LinkProps } from "react-router-dom";

import { LayoutProps, Variant, Content } from "./Button.types";
import * as S from "./Button.styles";

const isReactRouterLink = (
  props: any
): props is StyledComponentProps<
  typeof Link,
  any,
  LinkProps & LayoutProps,
  never
> => !!props.to;

const isAnchor = (
  props: any
): props is StyledComponentProps<"a", any, LayoutProps, never> => !!props.href;

export type Props =
  | StyledComponentProps<"a", any, LayoutProps, never>
  | StyledComponentProps<Link, any, LinkProps & LayoutProps, never>
  | StyledComponentProps<"button", any, LayoutProps, never>;

const Button: FC<Props> = props => {
  const defaults = {
    variant: props.variant || Variant.primary,
    content: props.content || Content.text,
    capitalized: props.capitalized ?? true
  };

  if (isReactRouterLink(props)) {
    return <S.Layout as={Link} {...props} {...defaults} />;
  }

  if (isAnchor(props)) {
    return (
      <S.Layout
        as="a"
        target="_blank"
        rel="noopener"
        {...props}
        {...defaults}
      />
    );
  }

  return <S.Layout {...props} {...defaults} />;
};

export default Button;
