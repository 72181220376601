import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import copyToClipboard from "copy-to-clipboard";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { getSubscriptionEditUrl } from "Libs/utils";
import CopyIcon from "Icons/CopyIcon";
import SuccessStateIcon from "Icons/SuccessStateIcon";
import IconMore from "Icons/IconMore";
import ActionDropdown from "../ActionDropdown";

import * as S from "./ProjectMoreLinks.styles";

const ProjectMoreLinks = ({
  iconColor = "white",
  from,
  project,
  subscription,
  toggleProjectWizard,
  isNav = false
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const intl = useIntl();

  const subscriptionStatus = useSelector(({ subscription }) =>
    subscription?.get("status")
  );

  useEffect(() => {
    let isCanceled = false;
    let timer;
    if (isCopied) {
      timer = setTimeout(() => !isCanceled && setIsCopied(false), 500);
    }
    return () => {
      isCanceled = true;
      clearTimeout(timer);
    };
  }, [isCopied]);

  useEffect(() => {
    if (subscriptionStatus === "deleted")
      window.location.replace(window.location.origin);
  }, [subscriptionStatus]);

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription }),
    [project, subscription]
  );

  const copyId = e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent?.stopPropagation();
    setIsCopied(true);
    copyToClipboard(project.id);
  };

  const handleEditSubscription = e => {
    e?.preventDefault();
    e?.nativeEvent.stopPropagation();
    window.location.href = subscriptionEditUrl.url;
  };

  if (
    (!project && !subscription) ||
    (!subscriptionEditUrl && !toggleProjectWizard)
  ) {
    return null;
  }
  return (
    <S.Wrapper isNav={isNav}>
      <ActionDropdown
        icon={<IconMore color={iconColor} />}
        ariaLabel="More"
        withArrow={false}
        className="more"
        id={project?.subscription_id || subscription?.id}
      >
        <S.List>
          <li role="menuitem">
            <S.ListItem
              variant="secondary"
              onClick={copyId}
              id="copy-project-id"
            >
              <S.Icon>
                {isCopied ? (
                  <SuccessStateIcon aria-hidden="true" />
                ) : (
                  <CopyIcon aria-hidden="true" />
                )}
              </S.Icon>
              {intl.formatMessage(
                { id: "project.morelinks.id", defaultMessage: "ID {id}" },
                {
                  id: project.id || subscription?.project_id
                }
              )}
            </S.ListItem>
          </li>

          {subscriptionEditUrl && (
            <li role="menuitem" data-testid="sub-link-edit">
              {subscriptionEditUrl.external ? (
                <S.ListItem
                  variant="secondary"
                  onClick={handleEditSubscription}
                >
                  {intl.formatMessage({
                    id: "project.morelinks.edit",
                    defaultMessage: "Edit plan"
                  })}
                </S.ListItem>
              ) : (
                <S.ListItem
                  variant="secondary"
                  to={{
                    pathname: subscriptionEditUrl.url,
                    state: { from }
                  }}
                >
                  {intl.formatMessage({
                    id: "project.morelinks.edit",
                    defaultMessage: "Edit plan"
                  })}
                </S.ListItem>
              )}
            </li>
          )}

          {toggleProjectWizard && (
            <li role="menuitem" data-testid="toggle-wizard">
              <S.ListItem variant="secondary" onClick={toggleProjectWizard}>
                {intl.formatMessage({
                  id: "project.morelinks.wizard",
                  defaultMessage: "Setup wizard"
                })}
              </S.ListItem>
            </li>
          )}
        </S.List>
      </ActionDropdown>
    </S.Wrapper>
  );
};

ProjectMoreLinks.propTypes = {
  iconColor: PropTypes.string,
  from: PropTypes.string,
  project: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  toggleProjectWizard: PropTypes.func,
  isNav: PropTypes.bool
};

export default ProjectMoreLinks;
