import styled, { css as styledCss } from "styled-components";
import Button from "ds/Button";

import {
  css,
  ICON,
  LINK,
  getCSSVarString,
  MENU_ITEM,
  BUTTON
} from "Libs/themes";

export const ListItem = styled(Button)`
  justify-content: start;
  font-weight: 400;
  font-size: 14px;
  color:var(--link-ebony-color,var(--link-ebony,var(--ebony)));
`;

export const Wrapper = styled.div`
  .more .tooltip {
    width: max-content;
    ${ListItem} {
      margin-bottom: 0;
    }
  }
  .tooltip > section {
    padding-top: 11px;
    padding-bottom: 12px;
    a${ListItem}:hover {
      color:var(--more-menu-item-menu-item-color,var(--menu-item-skye-color,var(--menu-item-skye,var(--skye))));
    }
  }
  .tooltip-content-wrapper {
    padding-top: 11px;
    padding-bottom: 12px;
  }

  ${({ isNav }) =>
    isNav &&
    styledCss`
        .more button.info-icon {
          svg path {
            fill:var(--icon-snow-fill,var(--icon-snow,var(--snow)));
          }

          &:hover,
          &.open {
            background-color:var(--button-dark-tint-background-color,var(--button-dark-tint,var(--dark-tint)));

            svg path {
              fill:var(--icon-snow-fill,var(--icon-snow,var(--snow)));
            }
          }
        }
      `}
`;

export const Icon = styled.div`
  display: flex;
  margin-right: 4px;
  svg path {
    fill:var(--more-menu-item-icon-fill,var(--icon-slate-fill,var(--icon-slate,var(--slate))));
  }
`;

export const List = styled.ul`
  li {
    &:not(:last-child) {
      padding-bottom: 0 !important;
    }
    ${ListItem} {
      width: 100%;
      padding: 0 8px !important;
      &:hover {
        color:var(--more-menu-item-menu-item-color,var(--menu-item-skye-color,var(--menu-item-skye,var(--skye))));
        background: ${({ theme }) =>
          theme.name === "contrast"
            ? "transparent"
            :"var(--menu-item-skye-light-background-color,var(--menu-item-skye-light,var(--skye-light)))"};
        ${Icon} svg path {
          fill:var(--more-menu-item-icon-fill,var(--icon-skye-fill,var(--icon-skye,var(--skye))));
        }
      }
    }
  }
`;
