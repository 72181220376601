import { ReactNode } from "react";

export enum Variant {
  short = "short",
  long = "long",
  button = "button"
}

export enum Priority {
  critical = "critical",
  normal = "normal",
  low = "low",
  information = "information"
}

export type Props = {
  variant?: keyof typeof Variant;
  priority?: keyof typeof Priority;
  children: ReactNode;
  hasIcon?: boolean;
  text: ReactNode;
  closeIcon?: ReactNode;
  button?: ReactNode;
  link?: ReactNode;
};
