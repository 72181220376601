import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Loading from "Components/Loading";
import ModalWrapper from "Components/Modal";
import ModalBody from "Components/ModalBody";
import { withReducers } from "Hocs";
import { meSelector } from "Reducers/app";
import {
  loadSourceOperations,
  runOperation,
  sourceOperationSelector
} from "Reducers/environment/sourceOperations";
import { projectCapabilitySelector } from "Reducers/project/capabilities";
import ConfigureOperationNotice from "./notice/ConfigureSourceOps";
import UpgradeSourceOps from "./notice/UpgradeSourceOps";
import RunSourceOps from "./RunOperation";
import * as S from "./SourceOperation.style";
import { ADMIN_ROLES } from "Constants/constants";
import getIsLoadingState from "Reducers/utils";

const SourceOperations = ({
  isOpen,
  onClose,
  organizationId,
  projectId,
  environmentId
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    state =>
      state.sourceOperations.get("loading") ||
      getIsLoadingState(state.capabilities, [organizationId, projectId])
  );

  const history = useHistory();

  const { sourceOperations, capabilities, me } = useSelector(state => ({
    sourceOperations: sourceOperationSelector(state, {
      organizationId,
      projectId,
      environmentId
    }),
    capabilities: projectCapabilitySelector(state, {
      organizationId,
      projectId
    }),
    me: meSelector(state)
  }));

  // The source_operations capability won't be released until Git 14. Until then,
  // we don't gate this feature based on tier or capability data. After Git 14 is released—
  // the CTA will start showing when appropriate.
  const shouldCtaForUpgrade =
    typeof capabilities?.source_operations === "undefined" ||
    capabilities?.source_operations?.enabled === false;

  const hasSourceOps = sourceOperations?.length;

  const isAdmin = ADMIN_ROLES.every(safeRole =>
    me.getIn(["data", "roles"])?.includes(safeRole)
  );

  // A User is allowed to run source operation if they have admin privilege
  // and source operation is configured, regardless of the project capabilities
  const canRunSourceOpsAsAdmin = isAdmin && hasSourceOps;

  const run = (sourceOperation, variables) => {
    const then = ({ data }) => {
      onClose();
      history.push(
        `/${organizationId}/${projectId}/${environmentId}/log/${data.id}`
      );
    };
    dispatch(runOperation({ sourceOperation, variables, then }));
  };

  useEffect(() => {
    if (capabilities?.source_operations?.enabled)
      dispatch(
        loadSourceOperations({
          organizationId,
          projectId,
          environmentId
        })
      );
  }, [organizationId, projectId, environmentId, capabilities]);

  return (
    <ModalWrapper
      closeModal={() => onClose()}
      onClose={onClose}
      modalClass={`${hasSourceOps ? "modal-large pb-0" : "modal-small"}`}
      isOpen={isOpen}
    >
      {isLoading ? (
        <S.LoadingWrapper>
          <Loading />
        </S.LoadingWrapper>
      ) : (
        <ModalBody>
          {shouldCtaForUpgrade && !canRunSourceOpsAsAdmin ? (
            <UpgradeSourceOps
              sourceOperations={sourceOperations}
              onClose={onClose}
            />
          ) : hasSourceOps ? (
            <RunSourceOps
              run={run}
              sourceOperations={sourceOperations}
              onClose={onClose}
            />
          ) : (
            <ConfigureOperationNotice
              sourceOperations={sourceOperations}
              onClose={onClose}
            />
          )}
        </ModalBody>
      )}
    </ModalWrapper>
  );
};

SourceOperations.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  environmentId: PropTypes.string
};

export default withReducers({
  sourceOperations: () => import("Reducers/environment/sourceOperations")
})(SourceOperations);
