import ActionDropdown from "Components/ActionDropdown";
import IconMore from "Icons/IconMore";
import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import * as S from "./MoreEnvironmentAction.style";

export const MORE_ACTION_ID = {
  BACKUP: "backup",
  BRANCH: "branch",
  MERGE: "merge",
  REDEPLOY: "redeploy",
  SOURCE_OPS: "source_operations",
  SYNC: "sync",
  DELETE_ENVIRONMENT: "delete_environment"
};

const MoreEnvironmentAction = ({ environment, onClick }) => {
  const formatMessage = useIntl().formatMessage;

  return (
    <S.Wrapper>
      <ActionDropdown
        icon={<IconMore color={"white"} />}
        ariaLabel="More"
        withArrow={false}
        className="more"
        id="environment-actions-dropdown"
      >
        <S.List>
          {environment.hasLink("#branch") && (
            <li>
              <S.ListItem
                variant="secondary"
                onClick={event => onClick({ id: MORE_ACTION_ID.BRANCH, event })}
                id="environment-branch-btn"
              >
                {formatMessage({ id: "branch" })}
              </S.ListItem>
            </li>
          )}
          {environment.hasLink("#merge") && (
            <li>
              <S.ListItem
                variant="secondary"
                onClick={event => onClick({ id: MORE_ACTION_ID.MERGE, event })}
                id="environment-merge-btn"
              >
                {formatMessage({ id: "merge" })}
              </S.ListItem>
            </li>
          )}
          {environment.hasLink("#synchronize") && (
            <li>
              <S.ListItem
                variant="secondary"
                onClick={event => onClick({ id: MORE_ACTION_ID.SYNC, event })}
                id="environment-sync-btn"
              >
                {formatMessage({ id: "sync" })}
              </S.ListItem>
            </li>
          )}
          <li>
            <S.ListItem
              variant="secondary"
              onClick={event => onClick({ id: MORE_ACTION_ID.REDEPLOY, event })}
              id="environment-redeploy-btn"
            >
              {formatMessage({ id: "redeploy" })}
            </S.ListItem>
          </li>

          {process.env.ENABLE_SOURCE_OPERATION && (
            <li>
              <S.ListItem
                variant="secondary"
                onClick={event =>
                  onClick({ id: MORE_ACTION_ID.SOURCE_OPS, event })
                }
              >
                {formatMessage({ id: "run_source_link_message" })}
              </S.ListItem>
            </li>
          )}

          {/* TODO implement delete environment */}
          {/* <li>
          <S.ListItem
  variant="secondary"
            onClick={event =>
              onClick({ id: MORE_ACTION_ID.DELETE_ENVIRONMENT, event })
            }
          >
            {formatMessage({ id: "delete_environment" })}
          </S.ListItem>
        </li> */}
          {environment.hasLink("#backup") && (
            <li>
              <S.ListItem
                variant="secondary"
                id="environment-backup-btn"
                onClick={event => onClick({ id: MORE_ACTION_ID.BACKUP, event })}
              >
                {formatMessage({ id: "backup" })}
              </S.ListItem>
            </li>
          )}
        </S.List>
      </ActionDropdown>
    </S.Wrapper>
  );
};

MoreEnvironmentAction.propTypes = {
  environment: PropTypes.object,
  onClick: PropTypes.func
};

export default MoreEnvironmentAction;
