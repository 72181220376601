import PropTypes from "prop-types";
import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom";

import ChevronIcon from "Components/icons/ChevronIcon";
import { getCSSVarString, ICON } from "Libs/themes";

const UserAvatar = ({ user, profile, layoutRef, setIsOpen, isOpen }) => {
  const { display_name, picture } = profile || {};

  useLayoutEffect(() => {
    const handleClickOutside = event => {
      if (
        isOpen &&
        !ReactDOM.findDOMNode(layoutRef.current)?.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, { capture: true });

    return () => {
      return document.removeEventListener("click", handleClickOutside, {
        capture: true
      });
    };
  }, [isOpen, layoutRef, setIsOpen]);

  return (
    <button
      onClick={() => setIsOpen(value => !value)}
      type="button"
      className={`user-menu-toggle${isOpen ? " open" : ""}${
        user.picture && !user.picture.endsWith("user-default.png")
          ? " picture"
          : ""
      }`}
      aria-label="User menu"
      aria-haspopup="true"
      aria-expanded={isOpen}
    >
      {picture && !picture.endsWith("user-default.png") ? (
        <img src={picture} alt={display_name} />
      ) : (
        display_name
      )}
      <ChevronIcon color={"var(--icon-granite-fill,var(--icon-granite,var(--granite)))"} />
    </button>
  );
};

UserAvatar.propTypes = {
  user: PropTypes.object,
  profile: PropTypes.object,
  layoutRef: PropTypes.object,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool
};

export default UserAvatar;
