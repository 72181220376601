export default {
  git: { label: "git", types: ["environment.push"] },
  merge: { label: "merge", types: ["environment.merge"] },
  sync: { label: "sync", types: ["environment.synchronize"] },
  branch: { label: "branch", types: ["environment.branch"] },
  crons: { label: "crons", types: ["environment.cron"] },
  backup: {
    label: "backup",
    types: [
      "environment.backup",
      "environment.backup.delete",
      "environment.restore"
    ]
  },
  redeploy: { label: "redeploy", types: ["environment.redeploy"] },
  certificate: {
    label: "certificate",
    types: ["environment.certificate.renewal"]
  },
  system: {
    label: "system",
    types: [
      "project.create",
      "project.modify.title",
      "environment.route.create",
      "environment.route.delete",
      "environment.route.update",
      "environment.access.add",
      "environment.access.remove",
      "environment.delete",
      "environment.initialize",
      "environment.activate",
      "environment.deactivate",
      "environment.merge-pr",
      "environment.update.smtp",
      "environment.update.http_access",
      "environment.subscription.update",
      "environment.variable.delete",
      "environment.variable.update",
      "environment.variable.create"
    ]
  },
  source_operation: {
    label: "source_operation",
    types: ["environment.source-operation"]
  },
  domain: {
    label: "filter.domain",
    types: [
      "environment.domain.create",
      "environment.domain.delete",
      "environment.domain.update",
      "project.domain.create",
      "project.domain.delete",
      "project.domain.update"
    ]
  },
  integrations: {
    label: "filter.integration",
    types: [
      "integration.webhook",
      "integration.script",
      "integration.health.slack",
      "integration.health.pagerduty",
      "integration.health.email",
      "integration.gitlab.fetch",
      "integration.github.fetch",
      "integration.bitbucket_server.register_hooks",
      "integration.bitbucket_server.fetch",
      "integration.bitbucket.fetch",
      "integration.bitbucket.register_hooks"
    ]
  }
};
