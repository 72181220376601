import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import momentjs from "moment";

import useDecodedParams from "Hooks/useDecodedParams";

import { getEnvironmentURI } from "Libs/utils";
import { getCSSVarString, ICON } from "Libs/themes";

import { ACTIVITY_CONTEXT } from "Constants/constants";
import { getSelectors } from "Reducers/activity";

import IconEnvBranch from "Components/icons/IconEnvBranch";
import IconEnvMaster from "Components/icons/IconEnvMaster";
import IconLink from "Components/icons/IconLink";
import WingsIcon from "Components/icons/WingsIcon";
import InProgressStateIcon from "Components/icons/InProgressStateIcon";
import { SuccessIcon, WarningIcon } from "@platformsh/ui-kit";

import AccessibleTooltip from "Components/AccessibleTooltip";
import EnvironmentRoutes from "Containers/EnvironmentRoutes";

import * as S from "./EnvironmentListBar.styles";

const REJECTED_ACTIVITIES = [
  "backup",
  "restore",
  "migrate",
  "backup.delete",
  "cron"
];

const EnvironmentListBar = ({
  className = "",
  displayMode = "tree",
  node,
  titleFilter
}) => {
  const intl = useIntl();
  const { organizationId, projectId } = useDecodedParams();

  const [isHover, setIsHover] = useState(false);

  const { inProgress } = getSelectors(ACTIVITY_CONTEXT.Project, {
    projectId,
    organizationId
  });
  const activities = useSelector(inProgress);

  const environmentURI = useMemo(
    () => getEnvironmentURI(organizationId, node.projectId, node.environmentId),
    [node, organizationId]
  );
  const isDeploying = useMemo(
    () =>
      activities.some(
        activity =>
          !REJECTED_ACTIVITIES.some(
            type => `${ACTIVITY_CONTEXT.Environment}.${type}` === activity.type
          ) && activity.data.environments.includes(node.environmentId)
      ),
    [activities]
  );

  // We need to use this method because if we pass tooltipProps to div we
  // have a warning from React
  const getTooltip = (props, children) => {
    if (isHover) {
      return <AccessibleTooltip {...props}>{children}</AccessibleTooltip>;
    }
    return <>{children}</>;
  };

  return (
    <S.Wrapper
      className={className}
      id={`environment-listbar-link-${node.environmentId}`}
      tabIndex="0"
      to={environmentURI}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {node.isRoot ? (
        <>
          {getTooltip(
            {
              tooltipProps: {
                id: `${node.environmentId}-root`,
                children: (
                  <>
                    {intl.formatMessage({
                      id: `environmentTree.line.root${
                        node.status === "inactive" ? ".inactive" : ""
                      }`
                    })}
                  </>
                )
              }
            },
            <S.IconRootWrapper>
              <IconEnvMaster
                color={getCSSVarString(
                  ICON,
                  "snow",
                  "fill",
                  "root-environment"
                )}
              />
            </S.IconRootWrapper>
          )}
        </>
      ) : (
        <>
          {node.children?.length > 0 ? (
            <>
              {getTooltip(
                {
                  tooltipProps: {
                    id: `${node.environmentId}-children`,
                    children: (
                      <>
                        {intl.formatMessage({
                          id: `environmentTree.line.parent${
                            node.status === "inactive" ? ".inactive" : ""
                          }`
                        })}
                      </>
                    )
                  }
                },
                <S.IconWrapper>
                  <IconEnvBranch
                    color={"var(--icon-skye-fill,var(--icon-skye,var(--skye)))"}
                  />
                </S.IconWrapper>
              )}
            </>
          ) : (
            <>
              {node.status === "inactive" ? (
                <>
                  {getTooltip(
                    {
                      tooltipProps: {
                        id: `${node.environmentId}-inactive`,
                        children: "Inactive child branch"
                      }
                    },
                    <S.IconWrapper>
                      <IconEnvBranch
                        color={"var(--icon-granite-fill,var(--icon-granite,var(--granite)))"}
                      />
                    </S.IconWrapper>
                  )}
                </>
              ) : (
                <S.IconWrapper>
                  <IconEnvBranch
                    color={"var(--icon-skye-fill,var(--icon-skye,var(--skye)))"}
                  />
                </S.IconWrapper>
              )}
            </>
          )}
        </>
      )}

      <S.Title environmentType={node.type} status={node.status}>
        <Highlighter
          highlightClassName="blue"
          searchWords={[titleFilter]}
          textToHighlight={node.title || ""}
          autoEscape={true}
          activeIndex={-1}
          caseSensitive={false}
        />
      </S.Title>

      {displayMode === "list" && (
        <>
          <S.Type>{node.type}</S.Type>

          <S.CreatedAt>
            {getTooltip(
              {
                tooltipProps: {
                  id: `${node.environmentId}-created_at`,
                  children: momentjs(node.created_at).format(
                    "h:mm a on MMMM D, YYYY"
                  )
                }
              },
              momentjs(node.created_at).format("D MMM YYYY")
            )}
          </S.CreatedAt>
        </>
      )}

      <S.Deployment>
        {isDeploying ? (
          <S.InProgress>
            <S.InProgressText>
              {intl.formatMessage({
                id: "environmentTree.line.deployment.inprogress"
              })}
            </S.InProgressText>
            <InProgressStateIcon />
          </S.InProgress>
        ) : (
          <>
            {node.deployment_state?.last_deployment_at && (
              <S.DeploymentDetails
                id={node.environmentId}
                label={momentjs(
                  node.deployment_state?.last_deployment_at
                ).fromNow()}
                icon={
                  node.deployment_state?.last_deployment_successful ? (
                    <SuccessIcon
                      color={"var(--icon-java-fill,var(--icon-java,var(--java)))"}
                      size={18}
                    />
                  ) : (
                    <WarningIcon size={18} />
                  )
                }
                iconAfter={true}
                tooltipWidth={225}
                withArrow={false}
                withBorder={false}
                withPortal={true}
                handlerType="hover"
              >
                <S.DeploymentInfo>
                  {intl.formatMessage({
                    id: `environmentTree.line.deployment.last.${
                      node.deployment_state?.last_deployment_successful
                        ? "success"
                        : "failed"
                    }`
                  })}
                  <S.CommitSha>
                    {intl.formatMessage(
                      { id: "environmentTree.line.commit" },
                      {
                        sha: node.head_commit?.substring(0, 7)
                      }
                    )}
                  </S.CommitSha>
                  <S.DeployedAt>
                    {momentjs(node.deployment_state?.last_deployment_at).format(
                      "h:mma MMM D, YYYY"
                    )}
                  </S.DeployedAt>
                </S.DeploymentInfo>
              </S.DeploymentDetails>
            )}
          </>
        )}

        {node.status !== "inactive" && node.deployment_target !== "local" && (
          <S.WingsDialog
            icon={<WingsIcon />}
            title={intl.formatMessage({
              id: "dedicatedenv.dialog.title"
            })}
            text={intl.formatMessage({
              id: "dedicatedenv.dialog.text"
            })}
            linkText={intl.formatMessage({ id: "learnmore" })}
            to={intl.formatMessage({
              id: "links.documentation.dedicated_cluster"
            })}
            align="center"
          />
        )}
      </S.Deployment>

      {node.status !== "inactive" ? (
        <S.ActionDropdown
          id={`${node.environmentId}-urls`}
          ariaLabel={intl.formatMessage(
            { id: "environments.links_tooltip" },
            {
              title: node.title
            }
          )}
          withArrow={false}
          icon={<IconLink />}
          withPortal={true}
        >
          <EnvironmentRoutes
            projectId={node.projectId}
            organizationId={organizationId}
            environmentId={node.environmentId}
            routeMaxNumber={5}
          />
        </S.ActionDropdown>
      ) : (
        <S.Spacer />
      )}
    </S.Wrapper>
  );
};

EnvironmentListBar.propTypes = {
  className: PropTypes.string,
  displayMode: PropTypes.string,
  node: PropTypes.object.isRequired,
  titleFilter: PropTypes.string
};

export default EnvironmentListBar;
