import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import moment from "moment-timezone";

import ModalWrapper from "Components/Modal";
import { Button, ButtonWrapper, Link } from "ds/Button";
import VoucherIcon from "ds/Icons/Voucher";
import { css, ICON } from "Libs/themes";

import * as S from "./ModalWelcome.styles";

const ModalWelcome = ({
  closeModal,
  currentTime,
  isOpen,
  organizationId,
  user
}) => {
  const intl = useIntl();
  if (!user?.current_trial) return null;
  const { expiration, spend } = user.current_trial;

  // formatted string of amount that will display in modal
  const amount = spend?.formatted || "$30";

  const expirationDate = moment(expiration);

  // Add 1 day to get total duration, inclusive of start date
  const duration = expirationDate.diff(currentTime, "days") + 1;

  return (
    <ModalWrapper
      closeModal={closeModal}
      id="trial-modal-welcome"
      isOpen={isOpen}
      modalClass="modal-small"
      title={intl.formatMessage({
        id: "trial.modal.welcome.title"
      })}
    >
      <S.ModalBody>
        {intl.formatMessage(
          {
            id: "trial.modal.welcome.text"
          },
          {
            amount,
            duration
          }
        )}
        <S.Voucher>
          <VoucherIcon fill={"fill:var(--icon-ebony-light-fill,var(--icon-ebony-light,var(--ebony-light)))"} />
          {intl.formatMessage(
            { id: "trial.modal.welcome.voucher" },
            {
              b: (...chunks) => <strong>{chunks}</strong>, // eslint-disable-line react/display-name
              // eslint-disable-next-line react/display-name
              link: (...chunks) => (
                <Link to={`/${organizationId}/-/billing/vouchers`}>
                  {chunks}
                </Link>
              )
            }
          )}
        </S.Voucher>
      </S.ModalBody>

      <ButtonWrapper spacing="modal" justifyContent="end">
        <Button id="welcome-modal-button" onClick={closeModal}>
          {intl.formatMessage({ id: "okay" })}
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  );
};

ModalWelcome.propTypes = {
  closeModal: PropTypes.func,
  currentTime: PropTypes.object,
  isOpen: PropTypes.bool,
  organizationId: PropTypes.string,
  user: PropTypes.object
};

export default ModalWelcome;
