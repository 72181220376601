import MenuDropdownWrapper from "Components/MenuDropdownWrapper";

import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { getUrls } from "Libs/utils";

import MenuLink from "../MenuLink";
import * as S from "../styles";
import UserAvatar from "./UserAvatar";

const OrganizationMenu = ({
  user,
  accountUrl,
  organizations,
  organizationId,
  profile
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const layoutRef = useRef(null);

  const organization = organizations?.get(organizationId);

  const formatMessage = useIntl().formatMessage;

  const urls = getUrls({
    accountUrl,
    username: user.username,
    organizationName: organization?.name
  });

  const isVendor = process.env.VENDOR_NAME || process.env.VENDOR_URL;

  return (
    <S.Layout ref={layoutRef}>
      <UserAvatar
        layoutRef={layoutRef}
        setIsOpen={setIsOpen}
        user={user}
        isOpen={isOpen}
        profile={profile}
      />

      <MenuDropdownWrapper className="menu-dropdown" isOpen={isOpen}>
        <ul>
          {process.env.ENABLE_ORGANIZATION && (
            <S.OrganizationLabel>{organization?.label}</S.OrganizationLabel>
          )}
          {process.env.CUSTOM_VENDOR_CONSOLE_URL &&
            process.env.CUSTOM_VENDOR_CONSOLE_TITLE && (
              <li>
                <MenuLink
                  label={process.env.CUSTOM_VENDOR_CONSOLE_TITLE}
                  url={process.env.CUSTOM_VENDOR_CONSOLE_URL}
                />
              </li>
            )}

          {
            // Only a member or owner of an org would have the self link
            organization?.hasLink("self") && (
              <>
                <li>
                  <MenuLink
                    label={formatMessage({ id: "menu.settings" })}
                    url={urls.organizationSettingsUrl}
                  />
                </li>
                {organization?.hasLink("members") && (
                  <li>
                    <MenuLink
                      label={formatMessage({ id: "menu.users" })}
                      url={urls.organizationUsersUrl}
                    />
                  </li>
                )}
                {organization?.hasLink("orders") &&
                  (process.env.ENABLE_BILLING ||
                    process.env.CUSTOM_BILLING_URL) && (
                    <li>
                      <MenuLink
                        label={formatMessage({ id: "menu.billing" })}
                        url={urls.organizationBillingUrl}
                      />
                    </li>
                  )}
                {!isVendor ? (
                  <li>
                    <S.CreateOrgSeparator isTop />
                    <Link to={getUrls().createOrganizationUrl}>
                      <S.CreateOrg>
                        <S.Plus />
                        <S.CreateOrgText>
                          {formatMessage({ id: "create_organization" })}
                        </S.CreateOrgText>
                      </S.CreateOrg>
                    </Link>
                    <S.CreateOrgSeparator />
                  </li>
                ) : (
                  <S.CreateOrgSeparator />
                )}
              </>
            )
          }

          <li>
            <MenuLink
              label={formatMessage({ id: "menu.my_profile" })}
              url={urls.profileUrl}
            />
          </li>

          <li>
            <MenuLink
              label={formatMessage({ id: "menu.support" })}
              url={urls.supportUrl}
            />
          </li>
          <li>
            <MenuLink
              label={formatMessage({ id: "menu.documentation" })}
              target="_blank"
              rel="noopener noreferrer"
              url={urls.docsUrl}
            />
          </li>
          <li>
            <MenuLink
              label={formatMessage({ id: "menu.logout" })}
              url={urls.logoutUrl}
            />
          </li>
        </ul>
      </MenuDropdownWrapper>
    </S.Layout>
  );
};

OrganizationMenu.propTypes = {
  user: PropTypes.object,
  organizations: PropTypes.object,
  accountUrl: PropTypes.string,
  organizationId: PropTypes.string,
  breadcrumbs: PropTypes.node,
  profile: PropTypes.object
};

export default OrganizationMenu;
