import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProjectContext } from "Reducers/settingsMenu";

import useMediaQuery from "Hooks/useMediaQuery";

import NavigationMenu from "../NavigationMenu";
import FloatBox from "../FloatBox";
import ProjectMoreLinks from "Components/ProjectMoreLinks";

import {
  LiveButton,
  SettingsIcon,
  SettingsIconContainer,
  NavigationRightItemsContainer
} from "./Project.styles";

const ProjectNavBar = ({
  toggleProjectWizard,
  subscription,
  project,
  organizationId,
  projectId,
  ...props
}) => {
  const onDesktop = useMediaQuery("(min-width: 768px)");
  const history = useHistory();
  const dispatch = useDispatch();

  const settingsLink = `/${organizationId}/${projectId}/-/settings`;
  const renderMoreLinksDropdown = project && subscription;

  const handleSettingsClicked = e => {
    e.stopPropagation();
    dispatch(setProjectContext());
    history.push(settingsLink);
  };

  return (
    <FloatBox>
      <NavigationMenu
        project={project}
        subscription={subscription}
        organizationId={organizationId}
        projectId={projectId}
        {...props}
      />

      {onDesktop && (
        <NavigationRightItemsContainer>
          <LiveButton
            project={project}
            subscription={subscription}
            organizationId={organizationId}
          />
          <SettingsIconContainer id="settings" onClick={handleSettingsClicked}>
            <SettingsIcon />
          </SettingsIconContainer>
          {renderMoreLinksDropdown && (
            <ProjectMoreLinks
              from="project"
              project={project}
              subscription={subscription}
              toggleProjectWizard={toggleProjectWizard}
              isNav={true}
            />
          )}
        </NavigationRightItemsContainer>
      )}
    </FloatBox>
  );
};

ProjectNavBar.propTypes = {
  toggleProjectWizard: PropTypes.func,
  project: PropTypes.object,
  subscription: PropTypes.object,
  organizationId: PropTypes.string,
  projectId: PropTypes.string
};

export default ProjectNavBar;
