import styled from "styled-components";
import { css, BUTTON } from "Libs/themes";

import { SettingsIcon as BaseSettingsIcon } from "@platformsh/ui-kit";

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  z-index: 1;
  justify-content: flex-start;
  gap: 8px;
`;

export const Wrapper = styled.div`
  #environment-actions-dropdown-window {
    width: max-content;
    .tooltip-content-wrapper {
      padding-top: 11px;
      padding-bottom: 12px;
    }
  }
`;

export const SettingsIconContainer = styled.button`
  cursor: pointer;
  height: 32px;
  width: 32px;
  margin-right: 0;
  margin-left: 12px;
  line-height: 46px;
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: 2px;

  &:hover {
    background-color:var(--button-slate-background-color,var(--button-slate,var(--slate)));

    & svg {
      fill:var(--button-snow-fill,var(--button-snow,var(--snow)));
    }
  }
`;

export const SettingsIcon = styled(BaseSettingsIcon)`
  fill:var(--button-snow-fill,var(--button-snow,var(--snow)));
`;
