import { fromJS, Map } from "immutable";
import type EnvironmentBackup from "platformsh-client/types/model/EnvironmentBackup";

import { push } from "Reducers/app";
import logger from "Libs/logger";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MapStoreStateType, SetErrorMessageAction } from "Reducers/types";

type BackupEnvironmentPropType = {
  organizationId: string;
  projectId: string;
  environmentId: string;
};

export const backup = createAsyncThunk<any, BackupEnvironmentPropType>(
  "environment/backup",
  async (
    { organizationId, projectId, environmentId },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as MapStoreStateType;
      const environment = state.environment.getIn(
        ["data", organizationId, projectId, environmentId],
        {}
      );
      const backup = await environment.backup();
      dispatch(
        push({
          path: "/:organizationDescriptionId/:projectDescriptionId/:environmentDescriptionId/backups",
          args: {
            organizationDescriptionId: organizationId,
            projectDescriptionId: projectId,
            environmentDescriptionId: environmentId
          }
        })
      );
      return backup;
    } catch (err) {
      logger(err as Error, {
        action: "backup",
        meta: {
          organizationId,
          projectId,
          environmentId
        },
        payload: backup
      });
      return rejectWithValue(err);
    }
  }
);

const backups = createSlice({
  name: "backup",
  initialState: Map(),
  reducers: {
    clearBackup: (state, { payload }) =>
      state.removeIn([
        "data",
        payload.organizationId,
        payload.projectId,
        payload.environmentId
      ])
  },
  extraReducers: builder => {
    builder
      .addCase(backup.pending, state =>
        state.set("loading", true).remove("errors")
      )
      .addCase(backup.fulfilled, (state, action) => {
        const { organizationId, projectId, environmentId } = action.meta.arg;
        return state
          .setIn(
            ["data", organizationId, projectId, environmentId],
            action.payload
          )
          .set("loading", false)
          .remove("errors");
      })
      .addCase(backup.rejected, (state, action: SetErrorMessageAction) =>
        state
          .set("loading", false)
          .set("errors", fromJS({ message: action.payload.message }))
      );
  }
});

export const environmentBackupSelector = (
  state: MapStoreStateType,
  { organizationId, projectId, environmentId }: BackupEnvironmentPropType
): EnvironmentBackup | undefined =>
  state.backup?.getIn(["data", organizationId, projectId, environmentId]);

export const { clearBackup } = backups.actions;
export default backups.reducer;
