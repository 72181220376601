import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { goToEnvironment } from "Libs/utils";

import { EnvironmentListBar } from "../";

import * as S from "./Node.styles";

const hasChildWithState = (children, showEnvs) => {
  return children.some(
    child =>
      (child.status !== "inactive" && showEnvs.active) ||
      (child.status === "inactive" && showEnvs.inactive) ||
      hasChildWithState(child.children, showEnvs)
  );
};

const Node = ({
  node,
  organizationId,
  showEnvs,
  titleFilter,
  visibleNodes
}) => {
  const { push } = useHistory();

  const hasChildInState = useMemo(() => {
    if (
      (node.status !== "inactive" && !showEnvs.active) ||
      (node.status === "inactive" && !showEnvs.inactive)
    ) {
      return hasChildWithState(node.children, showEnvs);
    }

    return false;
  }, [node, showEnvs]);

  if (visibleNodes && !visibleNodes.includes(node.environmentId)) return null;

  if (
    (!node.children.length || !hasChildInState) &&
    ((node.status !== "inactive" && !showEnvs.active) ||
      (node.status === "inactive" && !showEnvs.inactive))
  ) {
    return null;
  }

  return (
    <S.NodeLayout
      depth={node.depth}
      hasChildren={node.children.length > 0}
      isRoot={node.isRoot}
      status={node.status}
    >
      <EnvironmentListBar
        node={node}
        organizationId={organizationId}
        titleFilter={titleFilter}
        onClick={() =>
          goToEnvironment(
            push,
            organizationId,
            node.projectId,
            encodeURIComponent(node.environmentId)
          )
        }
      />

      <S.Children>
        {node.children.map(child => (
          <Node
            key={`node-${child.environmentId}`}
            node={child}
            organizationId={organizationId}
            showEnvs={showEnvs}
            titleFilter={titleFilter}
            visibleNodes={visibleNodes}
          />
        ))}
      </S.Children>
    </S.NodeLayout>
  );
};

Node.propTypes = {
  node: PropTypes.object,
  organizationId: PropTypes.string,
  showEnvs: PropTypes.object,
  titleFilter: PropTypes.string,
  visibleNodes: PropTypes.array
};

export default Node;
