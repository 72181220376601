import React, { FC } from "react";

import Checked from "ds/Icons/Checked";

import * as S from "./Checkbox.styles";
import { getCSSVarString, ICON } from "Libs/themes";

export interface Props {
  /** Renders the error state if false */
  isValid: boolean;
  checked: boolean;
  label: React.ReactNode;
  onCheckedChanged: (checked: boolean) => void;
  error?: string;
  forId: string;
  id?: string;
  required?: boolean | undefined;
  disabled?: boolean | undefined;
}

const Checkbox: FC<Props> = ({
  onCheckedChanged,
  error,
  isValid = true,
  label,
  forId,
  disabled,
  required,
  id,
  checked,
  ...props
}) => (
  <S.Layout data-testid="root" {...props}>
    <S.Root
      id={forId}
      onCheckedChange={onCheckedChanged}
      disabled={disabled}
      required={required}
      name={forId || id}
      checked={checked}
      isValid={isValid}
    >
      <S.Indicator>
        <Checked
          fill={
            isValid
              ?"var(--icon-skye-color,var(--icon-skye,var(--skye)))":"var(--icon-red-fill,var(--icon-red,var(--red)))"
          }
        />
      </S.Indicator>
    </S.Root>
    <S.Label
      htmlFor={forId}
      id={id ? id : forId + "-label"}
      disabled={disabled}
    >
      {label}
    </S.Label>
    {error && <S.Error>{error}</S.Error>}
  </S.Layout>
);

export default Checkbox;
