import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

export const ModalBody = styled.div`
  color: ${props => props.theme.sectionText};
`;

export const Voucher = styled.div`
  margin-top: 24px;
  font-size: 14px;
  line-height: 24px;
  a {
    text-transform: none;
  }
  svg {
    margin-right: 4px;
    vertical-align: middle;
  }
  strong {
    ${semiBoldAlias};
  }
`;
