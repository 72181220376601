import React, { FC } from "react";

import CloseIcon from "Icons/CloseIcon";
import { Props as ButtonProps } from "./Button";

import * as S from "./CloseButton.styles";

export type Props = {
  className?: string;
} & ButtonProps;

const CloseButton: FC<ButtonProps> = ({ children, className, ...props }) => (
  <S.Layout className={className}>
    {children && <S.Text>{children}</S.Text>}
    <S.Icon variant="secondary" {...props} content="icon">
      <CloseIcon />
    </S.Icon>
  </S.Layout>
);

export default CloseButton;
