import styled, { css } from "styled-components";

import { Variant, Content, LayoutProps } from "./Button.types";

import { css as _css, getCSSVarString, BUTTON, LINK } from "Libs/themes";

const SIDE_PADDING_LG = 24;
const SIDE_PADDING_MD = 16;
const SIDE_PADDING_SM = 8;

export const __button_outline = css`
  ${_css(LINK, "skye-dark", "border-color")};
  box-shadow: 0px 0px 2px
      ${getCSSVarString(LINK, "skye-dark", "box-shadow-color")},
    inset 0 0 2px ${getCSSVarString(LINK, "skye-dark", "box-shadow-color")};
`;

export const __primary = css<LayoutProps>`
  transition-duration: 0.25s;
  transition-property: color, background-color, border-color, box-shadow;
  padding-left: ${SIDE_PADDING_LG}px;
  padding-right: ${SIDE_PADDING_LG}px;
  ${_css(BUTTON, "skye", "background-color")};
  ${_css(BUTTON, "snow", "color")};
  box-shadow: none;

  :focus {
    box-shadow: 0px 0px 0px 1px white,
      0px 0px 0px 2px ${getCSSVarString(LINK, "skye-dark", "box-shadow-color")},
      0px 0px 2px ${getCSSVarString(LINK, "skye-dark", "box-shadow-color")};
  }

  :hover {
    ${_css(BUTTON, "skye-dark", "background-color")};
  }

  :active {
    ${_css(BUTTON, "blue", "background-color")};
    box-shadow: none;
    border-color: transparent;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${_css(BUTTON, "light-blue", "background-color")};
      :focus {
        box-shadow: none;
      }
    `};
`;

export const __secondary = css<LayoutProps>`
  transition-duration: 0.25s;
  transition-property: color, border-color, box-shadow, background-color;
  padding-left: ${SIDE_PADDING_LG}px;
  padding-right: ${SIDE_PADDING_LG}px;
  background-color: transparent;
  ${_css(BUTTON, "slate", "color")};

  :focus {
    ${_css(BUTTON, "slate", "color", "active")};
    ${__button_outline};
  }

  :hover {
    ${_css(BUTTON, "slate", "color", "active")};
    ${_css(BUTTON, "grey", "background-color", "hover")};
  }

  :active {
    ${_css(BUTTON, "slate", "color", "active")};
    border-color: transparent;
    box-shadow: none;
    ${_css(BUTTON, "skye-light", "background-color")};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${_css(BUTTON, "periwinkle-grey", "color")};
    `};
`;

export const __outline = css<LayoutProps>`
  transition-duration: 0.25s;
  transition-property: border-color, color, background-color;

  padding-left: ${SIDE_PADDING_LG}px;
  padding-right: ${SIDE_PADDING_LG}px;
  background-color: transparent;
  ${_css(BUTTON, "periwinkle-grey", "border-color", "outline")};
  ${_css(BUTTON, "skye", "color")};

  :focus {
    ${__button_outline};
  }

  :hover {
    ${_css(BUTTON, "snow", "color")};
    border-color: transparent;
    ${_css(BUTTON, "skye", "background-color")};
  }

  :active {
    ${_css(BUTTON, "blue", "background-color")};
    border-color: transparent;
    box-shadow: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${_css(BUTTON, "light-blue", "color")};
      border-color: transparent;
    `};
`;

const SIDE_PADDING_LINK = 0;

export const __link = css<LayoutProps>`
  padding-left: ${SIDE_PADDING_LINK}px;
  padding-right: ${SIDE_PADDING_LINK}px;
  transition-duration: 0.25s;
  transition-property: border-color, box-shadow, color;
  font-weight: 400;
  ${_css(LINK, "skye", "color")};
  background-color: transparent;
  height: 24px;
  position: relative;

  :after {
    transition-duration: 0.25s;
    transition-property: background-color;
    content: "";
    position: absolute;
    top: 100%;
    left: ${SIDE_PADDING_LINK}px;
    height: 1px;
    width: calc(100% - ${SIDE_PADDING_LINK * 2}px);
    background-color: transparent;
  }

  :focus {
    ${__button_outline};
  }

  :hover {
    ${_css(LINK, "skye-dark", "color")};
  }

  :hover:after {
    ${_css(LINK, "skye-dark", "background-color")};
  }

  :active {
    border-color: transparent;
    box-shadow: none;
    color: var(--button-link-active, #222);
    ${_css(LINK, "blue", "color")};
  }

  :active:after {
    ${_css(LINK, "blue", "background-color")};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${_css(LINK, "light-blue", "color")};
    `};
`;

export const __dark = css<LayoutProps>`
  ${_css(BUTTON, "granite", "color")};
  background-color: transparent;
  transition-duration: 0.25s;
  transition-property: border-color, color, background-color;
  padding-left: ${SIDE_PADDING_LG}px;
  padding-right: ${SIDE_PADDING_LG}px;

  :hover {
    ${_css(BUTTON, "snow", "color")};
    ${_css(BUTTON, "slate", "background-color")};
  }

  :focus {
    ${__button_outline};
  }

  :active {
    ${_css(BUTTON, "snow", "color")};
    ${_css(BUTTON, "ebony-light", "background-color")};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${_css(BUTTON, "ebony-light", "color")};
    `};
`;

export const __mixed = css<LayoutProps>`
  padding-left: ${SIDE_PADDING_SM}px;
  padding-right: ${SIDE_PADDING_MD}px;

  > :first-child {
    display: inline-block;
    margin-right: 8px;
  }

  svg {
    color: currentColor;
  }

  svg * {
    fill: currentColor;
  }

  ${({ variant }) =>
    variant === Variant.link &&
    css`
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      padding-left: 0px;
      padding-right: 0px;

      > :first-child {
        margin-right: 4px;
      }
    `}

  ${({ variant, disabled }) =>
    variant === Variant.link &&
    disabled &&
    css`
      ${_css(BUTTON, "light-blue", "color")};
    `};
`;

const __icon = css<LayoutProps>`
  height: 32px;
  width: 32px;
  padding: 0;

  > :first-child {
    display: inline-block;
    height: 24px;
    width: 24px;
  }

  svg {
    color: currentColor;
  }

  svg * {
    fill: currentColor;
  }
`;

const __defaultDisabled = css`
  cursor: not-allowed;
  pointer-events: none;
`;

export const Layout = styled.button
  // Avoids passing down styling related props to the DOM
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !["capitalized"].includes(prop) && defaultValidatorFn(prop)
  })
  .attrs<LayoutProps>(props => ({
    type: props.type || "button",
    "data-ds": true
  }))<LayoutProps>`
  box-sizing: border-box;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  ${({ capitalized }) =>
    capitalized &&
    css`
      text-transform: capitalize;
    `}

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  mix-blend-mode: normal;
  border-radius: 2px;
  cursor: pointer;

  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  /* button style resets */
  outline: none;
  border: 1px solid transparent;
  padding: 0;
  margin: 0;

  /* anchor style resets */
  text-decoration: none;

  ${({ disabled }) => disabled && __defaultDisabled};

  ${({ variant }) => variant === Variant.primary && __primary};
  ${({ variant }) => variant === Variant.secondary && __secondary};
  ${({ variant }) => variant === Variant.outline && __outline};
  ${({ variant }) => variant === Variant.link && __link};
  ${({ variant }) => variant === Variant.dark && __dark};
  ${({ content }) => content === Content.mixed && __mixed};
  ${({ content }) => content === Content.icon && __icon};
`;
