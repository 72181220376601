import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import HeartIcon from "Icons/HeartIcon";
import { semiBoldAlias } from "Libs/theme";

const getTextColor = ({ theme }) =>
  theme?.name === "contrast" ? "#5f5e70" : "#98a0ab";

const Layout = styled.footer`
  font-size: 11px;
  color: ${getTextColor};
  opacity: ${props => (props.theme.name === "contrast" ? 1 : 0.5)};
  text-align: center;
  padding: 24px 0;
  margin-top: ${props => props.marginTop}px;
  ${semiBoldAlias()};
`;

const AppVersion = props => {
  const heartColor = getTextColor(props);
  const footerText = process.env.CUSTOM_FOOTER_TEXT || (
    <span>
      with <HeartIcon color={heartColor} />
    </span>
  );

  return (
    <Layout className="version" marginTop={0}>
      <FormattedMessage
        id="footer.message"
        values={{
          appVersion: process.env.VERSION_NUMBER,
          footerText
        }}
      />
    </Layout>
  );
};

AppVersion.propTypes = {
  theme: PropTypes.object
};

export default withTheme(AppVersion);
