import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Map, fromJS } from "immutable";

import config from "Constants/api_config";
import logger from "Libs/logger";
import { request } from "Libs/platform";
import { hasHtml } from "Libs/utils";

export const getOptions = createAsyncThunk(
  "app/setup/options",
  async (_, { getState, rejectWithValue }) => {
    const templateUrl = getState().setup?.getIn(["config", "template"]);
    let optionsParams = {
      environments: 3,
      storage: 5120,
      user_licenses: 1
    };

    if (templateUrl) optionsParams.options_url = templateUrl;

    try {
      const options = await request(
        `${config.api_url}/platform/setup/options`,
        "POST",
        optionsParams,
        {
          "Content-Type": "application/json"
        }
      );

      return options;
    } catch (err) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "setup_load_options"
        });
      }
      return rejectWithValue({ errors: err });
    }
  }
);

export const getCatalog = createAsyncThunk(
  "app/setup/catalog",
  async ({ catalogUrl }, { rejectWithValue }) => {
    try {
      const url = catalogUrl
        ? catalogUrl
        : `${config.api_url}/platform/setup/catalog${
            process.env.VENDOR_NAME ? `?vendor=${process.env.VENDOR_NAME}` : ""
          }`;
      const catalog = await request(url, "POST");
      return catalog;
    } catch (err) {
      logger(err, { action: "setup_load_catalog" });
      return rejectWithValue({ errors: err });
    }
  }
);

const setup = createSlice({
  name: "setup",
  initialState: Map(),
  reducers: {
    resetSetup: state => state.delete("config"),
    saveConfig: (state, { payload }) => state.mergeIn(["config"], payload)
  },
  extraReducers: {
    // GET OPTIONS
    [getOptions.pending]: state =>
      state.deleteIn(["options", "errors"]).setIn(["options", "loading"], true),
    [getOptions.fulfilled]: (state, { payload }) =>
      state
        .setIn(["options", "data"], fromJS(payload))
        .setIn(["options", "loading"], false),
    [getOptions.rejected]: (state, { payload }) =>
      state
        .setIn(["options", "loading"], false)
        .setIn(["options", "errors", payload.errors]),

    // GET CATALOG
    [getCatalog.pending]: state =>
      state.deleteIn(["catalog", "errors"]).setIn(["catalog", "loading"], true),
    [getCatalog.fulfilled]: (state, { payload }) =>
      state
        .setIn(["catalog", "data"], fromJS(payload))
        .setIn(["catalog", "loading"], false),
    [getCatalog.rejected]: (state, { payload }) =>
      state
        .setIn(["options", "loading"], false)
        .setIn(["options", "errors", payload.errors])
  }
});

export const { resetSetup, saveConfig } = setup.actions;
export default setup.reducer;
