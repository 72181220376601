import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Link } from "react-router-dom";

import useLocalForage from "Hooks/useLocalForage";
import Banner from "ds/Banner";
import { CloseButton } from "ds/Button";

const Trial = ({ user, organizationId }) => {
  const [isClosed, setIsClosed, , isLoading] = useLocalForage(
    "trial_banner_closed"
  );

  useEffect(() => {
    // coonvert to Array because previously we saved only a global
    // close state, now we have a state for each type of banner
    if (!Array.isArray(isClosed)) setIsClosed([]);
  }, [isLoading]);

  const { current_trial } = user;
  let count;
  if (current_trial && current_trial?.expiration) {
    // We add 1 day, because the expiration date is include to the period
    count = moment(current_trial.expiration).diff(moment(), "days") + 1;
  }

  const { msg, priority } = (() => {
    // Free trial not used or Day 1-20
    if (typeof count === "undefined" || count >= 20)
      return {
        priority: "information",
        msg: "state_1"
      };

    // Free trial Day 20-30
    if (count >= 1) return { priority: "information", msg: "state_2" };

    // Free trial Ended Week 1
    if (count > -6) return { priority: "high", msg: "state_3" };

    // Free trial Ended Week 2
    return { priority: "critical", msg: "state_4" };
  })();

  const handleClose = e => {
    e?.preventDefault();
    const banners = isClosed || [];
    setIsClosed([...banners, priority]);
  };

  if (
    !process.env.ENABLE_BILLING ||
    isLoading ||
    (Array.isArray(isClosed) && isClosed?.includes(priority))
  )
    return null;

  let billingUrl = `/billing`;
  if (process.env.ENABLE_ORGANIZATION) {
    billingUrl = `/${organizationId}/-/billing`;
  }

  return (
    <Banner
      priority={priority}
      closeBanner={<CloseButton onClick={handleClose} />}
      text={
        <FormattedMessage
          id={`banner.trial.${msg}`}
          values={{
            linkBilling: txt => <Link to={billingUrl}>{txt}</Link>
          }}
        />
      }
    />
  );
};

Trial.propTypes = {
  user: PropTypes.object,
  organizationId: PropTypes.string
};

export default Trial;
