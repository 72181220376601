import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { Link, Button, ButtonWrapper } from "ds/Button";

import * as S from "../SourceOperation.style";

export const UpgradeSourceOps = ({ onClose }) => {
  const formatMessage = useIntl().formatMessage;
  return (
    <S.SourceOperationContainer>
      <S.Heading>{formatMessage({ id: "source_ops.upgrade.title" })}</S.Heading>
      <S.OperationDescription className="operation-notice">
        {formatMessage({ id: "source_ops.upgrade.message" })}
      </S.OperationDescription>

      <ButtonWrapper spacing="modal" justifyContent="end">
        <Button variant="secondary" onClick={onClose}>
          {formatMessage({ id: "close" })}
        </Button>
        <Link variant="primary" href={formatMessage({ id: "links.pricing" })}>
          {formatMessage({ id: "view_pricing" })}
        </Link>
      </ButtonWrapper>
    </S.SourceOperationContainer>
  );
};

UpgradeSourceOps.propTypes = {
  onClose: PropTypes.func
};

export default UpgradeSourceOps;
