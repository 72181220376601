import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { capitalize } from "Libs/utils";
import Button, { Props } from "./Button";
import PlusIcon from "UI/icons/Plus";

const AddButton: FC<Props> = ({ children, ...props }) => (
  <Button {...props} content="mixed">
    <PlusIcon />
    {children ? (
      typeof children === "string" ? (
        capitalize(children)
      ) : (
        children
      )
    ) : (
      <FormattedMessage id="add" />
    )}
  </Button>
);

export default AddButton;
