export const ANALYTICS_ENABLED =
  process.env.GOOGLE_TAG_MANAGER_ID && process.env.NODE_ENV === "production";
export const PROJECT_ID_FIELD = "id";
export const ORGANIZATION_ID_FIELD = "name";
export const ENVIRONMENT_ID_FIELD = "id";
export const INTEGRATION_ID_FIELD = "id";
export const TEAM_ID_FIELD = "name";
export const SCREENSHOT_API = process.env.SCREENSHOT_API;
export const ADMIN_ROLES = ["administrator", "team member"];
export const SAFE_ROLES = [...ADMIN_ROLES, "invoice account"];
export const BILLING_PATH_MATCH =
  /^\/[A-z0-9-]+\/[A-z0-9-]+\/plan$|^\/-\/users\/[A-z0-9-]+\/billing/;
export const SETTINGS_PATH_MATCH =
  /^\/[A-z0-9-]+\/[A-z0-9-]+\/plan$|^\/-\/users\/[A-z0-9-]+\/settings/;
export const SUPPORT_PATH_MATCH =
  /^\/[A-z0-9-]+\/[A-z0-9-]+\/plan$|^\/-\/users\/[A-z0-9-]+\/tickets/;
export const INTEGRATIONS_KINDS = {
  git: ["bitbucket", "bitbucket_server", "github", "gitlab"],
  health: [
    "health.email",
    "health.pagerduty",
    "health.slack",
    "health.webhook"
  ],
  custom: ["hipchat", "script", "webhook", "newrelic", "sumologic"]
};
export const PLAN_TYPES = {
  "trial/development": "Trial",
  development: "Development",
  small: "Small",
  standard: "Standard",
  medium: "Medium",
  large: "Large",
  xlarge: "Xlarge",
  "2xlarge": "2xlarge"
};

export const BACKUP_TYPES = {
  DAILY: {
    label: "Daily",
    manual: 2,
    automated: 2
  },
  DAILY_PLUS: {
    label: "Daily+",
    manual: 4,
    automated: 21
  },
  PLATFORM_FEATURE_BACKUPS_DAILY_PLUS: {
    label: "Daily+",
    manual: 4,
    automated: 24
  },
  PLATFORM_FEATURE_BACKUPS_SIX_HOURLY: {
    label: "Four-a-Day",
    manual: 4,
    automated: 26
  }
};

export const BACKUP_DESCRIPTIONS = {
  Daily:
    "Automated backups are stored for 2 days. You can store up to 2 manual backups at any time.",
  "Daily+":
    "21 automated and 4 manual backups. {br}Each daily backup is stored for 1 week. {br}Each weekly backup is stored for 1 month. {br}Each monthly backup is stored for 1 year.",
  "Four-a-Day":
    "24 automated backups and 4 manual backups. {br}Each Four-a-Day backup is stored for 1 day. {br}Each daily backup is stored for 1 week. {br}Each weekly backup is stored for 1 month. {br}Each monthly backup is stored for 1 year."
};

export const GIG = 1024;
export const STORAGE_SUFFIX = "GB";

export const ENVIRONMENT_TYPES = ["production", "staging", "development"];
// Values used to parse the date into a natural language format
export const MOMENTJS_OPTIONS = {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "%d sec",
    ss: "%d secs",
    m: "%d min",
    mm: "%d mins",
    h: "%d hr",
    hh: "%d hrs",
    d: "%d day",
    dd: "%d days",
    M: "%d month",
    MM: "%d months",
    y: "%d year",
    yy: "%d years"
  }
};
export const PERMISSIONS = [
  { value: "billing", label: "Billing" },
  { value: "plans", label: "Plans" },
  { value: "members", label: "Users" },
  { value: "projects:create", label: "Create projects" }
];

export const ACTIVITY_CONTEXT = {
  Environment: "environment",
  Integration: "integration",
  Project: "project"
};
