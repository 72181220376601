import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";
import React, { FC } from "react";

const Info: FC<WrappingIconProps> = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path d="M12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2ZM12 10C11.4 10 11 10.4 11 11V16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16V11C13 10.4 12.6 10 12 10ZM12 7C11.4 7 11 7.4 11 8C11 8.6 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7Z" />
    </Icon>
  );
};

export default Info;
