import React from "react";
import Proptypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Banner from "ds/Banner";

const PaymentMissing = ({ organizationId }) => {
  const username = useSelector(state => state.app?.getIn(["me", "username"]));

  let billingUrl = `/-/users/${username}/billing`;
  if (process.env.ENABLE_ORGANIZATION) {
    billingUrl = `/${organizationId}/-/billing`;
  }

  return (
    <Banner
      priority={"critical"}
      text={
        <FormattedMessage
          id="banner.payment_missing"
          defaultMessage="Payment method missing. Add your <a>billing details</a> to keep your projects running."
          values={{
            a: txt => <Link to={billingUrl}>{txt}</Link>
          }}
        />
      }
    />
  );
};

PaymentMissing.propTypes = {
  organizationId: Proptypes.string
};

export default PaymentMissing;
