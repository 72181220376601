import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";
import React, { FC } from "react";

const Voucher: FC<WrappingIconProps> = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4C3.89572 4 3 4.89572 3 6V9.5C3 10.0523 3.44772 10.5 4 10.5C4.55272 10.5 5 10.9473 5 11.5C5 12.0527 4.55272 12.5 4 12.5C3.44772 12.5 3 12.9477 3 13.5V17C3 18.1043 3.89572 19 5 19H18C19.1043 19 20 18.1043 20 17V14C20 13.4477 19.5523 13 19 13C18.4473 13 18 12.5527 18 12C18 11.4473 18.4473 11 19 11C19.5523 11 20 10.5523 20 10V6C20 4.89572 19.1043 4 18 4H5ZM5 6H18V9.1706C16.8346 9.58229 16 10.6934 16 12C16 13.3066 16.8346 14.4177 18 14.8294V17H5V14.3294C6.16538 13.9177 7 12.8066 7 11.5C7 10.1934 6.16538 9.08229 5 8.6706V6ZM8 9C8 8.44772 8.44772 8 9 8H14C14.5523 8 15 8.44772 15 9C15 9.55228 14.5523 10 14 10H9C8.44772 10 8 9.55228 8 9ZM8 14C8 13.4477 8.44772 13 9 13H14C14.5523 13 15 13.4477 15 14C15 14.5523 14.5523 15 14 15H9C8.44772 15 8 14.5523 8 14Z"
      />
    </Icon>
  );
};

export default Voucher;
