import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

export const Live = styled.span`
  display: inline-block;
  background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  color:var(--section-skye-color,var(--section-skye,var(--skye)));
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  padding: 0 8px;
  ${semiBoldAlias()};
  border-radius: 2px;
`;
