import React, { useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import NavigationMenu from "Containers/NavBar/NavigationMenu";
import GoLiveButton from "Containers/NavBar/Environment/GoLiveButton";
import BranchIcon from "Icons/BranchIcon";
import MergeIcon from "Icons/MergeIcon";
import SyncIcon from "Icons/SyncIcon";

import { ActionButton } from "ds/Button";
import EnvironmentBackupModal from "./BackupModal";
import FloatBox from "Containers/NavBar/FloatBox";

import { withReducers } from "Hocs";
import { capitalize, getEnvironmentURI } from "Libs/utils";
import useMediaQuery from "Hooks/useMediaQuery";
import { breakpoints } from "Libs/theme";
import { setEnvironmentsContext } from "Reducers/settingsMenu";

import {
  ActionButtons,
  SettingsIcon,
  SettingsIconContainer,
  Wrapper
} from "./Environment.styles";
import MoreEnvironmentAction, {
  MORE_ACTION_ID
} from "./moreActions/MoreEnvironmentAction";
import { SourceOperations } from "./sourceOperations";

// this controls when to show the text in the action buttons
// for this to work the text must be wrapped in a <span> tag
const showCompleteButtonsMQ = `(min-width: 1060px)`;

const EnvironmentNavBar = ({
  project,
  environment,
  organizationId,
  projectId,
  environmentId,
  intl,
  ...props
}) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [openBackupModal, setBackupModalState] = useState(false);
  const [openSourceOperation, setOpenSourceOperation] = useState(false);
  const showActionButtons = useMediaQuery(`(min-width: ${breakpoints[1]})`);
  // We only show the golive button on main env so no need to move
  // action buttons into more env dropdown if we are not in the main env
  const showSyncButtonInMoreActionsDropdown =
    useMediaQuery(`(max-width: 880px)`) && environment.is_main;
  const showMergeButtonInMoreActionsDropdown =
    useMediaQuery(`(max-width: 826px)`) && environment.is_main;
  const showBranchButtonInMoreActionsDropdown =
    useMediaQuery(`(max-width: 780px)`) && environment.is_main;

  const history = useHistory();

  const settingsLink = `/${organizationId}/${projectId}/${encodeURIComponent(
    environmentId
  )}/settings`;

  const toggleBackupModal = () => {
    setBackupModalState(!openBackupModal);
  };

  const environmentUri = getEnvironmentURI(
    organizationId,
    projectId,
    environmentId
  );

  const handleMergeClicked = e => {
    if (environment.hasLink("#merge")) {
      e.preventDefault();
      document.activeElement.blur();
      history.push(`${environmentUri}/actions/merge`);
    }
  };

  const handleBranchClicked = e => {
    if (environment.hasLink("#branch")) {
      e.stopPropagation();
      document.activeElement.blur();
      history.push(`${environmentUri}/actions/branch`);
    }
  };

  const handleSyncClicked = e => {
    if (environment.hasLink("#synchronize")) {
      e.stopPropagation();
      document.activeElement.blur();
      history.push(`${environmentUri}/actions/sync`);
    }
  };

  const handleSettingsClicked = e => {
    e.stopPropagation();
    dispatch(setEnvironmentsContext(environmentId));
    history.push(settingsLink);
  };

  const onClickMoreAction = ({ id, event }) => {
    switch (id) {
      case MORE_ACTION_ID.BACKUP:
        if (environment.hasLink("#backup")) {
          event.preventDefault();
          document.activeElement.blur();
          toggleBackupModal();
        }
        break;
      case MORE_ACTION_ID.BRANCH:
        handleBranchClicked(event);
        break;
      case MORE_ACTION_ID.MERGE:
        handleMergeClicked(event);
        break;
      case MORE_ACTION_ID.REDEPLOY:
        environment.hasLink("#redeploy") &&
          history.push(`${environmentUri}/actions/redeploy`);
        event.preventDefault();
        break;
      case MORE_ACTION_ID.SOURCE_OPS:
        setOpenSourceOperation(!openSourceOperation);
        event.preventDefault();
        break;
      case MORE_ACTION_ID.SYNC:
        event.preventDefault();
        handleSyncClicked(event);
        break;
      case MORE_ACTION_ID.DELETE_ENVIRONMENT:
      //TODO DELETE delete environment
    }
  };

  return (
    <Wrapper>
      <FloatBox>
        <NavigationMenu
          project={project}
          environment={environment}
          environmentId={environmentId}
          organizationId={organizationId}
          projectId={projectId}
          {...props}
        />
        {showActionButtons && (
          <ActionButtons
            className={`action-buttons${
              environment && environment.status === "inactive"
                ? " inactive"
                : ""
            }`}
          >
            {path === "/:organizationId/:projectId/:environmentId" && (
              <React.Fragment>
                {!showBranchButtonInMoreActionsDropdown && (
                  <ActionButton
                    id="environment-branch-btn"
                    onClick={handleBranchClicked}
                    disabled={!environment.hasLink("#branch")}
                    tabIndex="0"
                    aria-label={intl.formatMessage({ id: "branch" })}
                    expandWhen={showCompleteButtonsMQ}
                  >
                    <BranchIcon />
                    <span>
                      {capitalize(intl.formatMessage({ id: "branch" }))}
                    </span>
                  </ActionButton>
                )}

                {!showMergeButtonInMoreActionsDropdown && (
                  <ActionButton
                    id="environment-merge-btn"
                    onClick={handleMergeClicked}
                    disabled={!environment.hasLink("#merge")}
                    tabIndex="0"
                    aria-label={intl.formatMessage({ id: "merge" })}
                    expandWhen={showCompleteButtonsMQ}
                  >
                    <MergeIcon />{" "}
                    <span>
                      {capitalize(intl.formatMessage({ id: "merge" }))}
                    </span>
                  </ActionButton>
                )}

                {!showSyncButtonInMoreActionsDropdown && (
                  <ActionButton
                    id="environment-synchronize-btn"
                    onClick={handleSyncClicked}
                    disabled={!environment.hasLink("#synchronize")}
                    aria-label={intl.formatMessage({ id: "sync" })}
                    expandWhen={showCompleteButtonsMQ}
                  >
                    <SyncIcon />{" "}
                    <span>
                      {capitalize(intl.formatMessage({ id: "sync" }))}
                    </span>
                  </ActionButton>
                )}

                <GoLiveButton />

                <SettingsIconContainer
                  id="settings"
                  onClick={handleSettingsClicked}
                >
                  <SettingsIcon />
                </SettingsIconContainer>

                {(path === "/:organizationId/:projectId/:environmentId" ||
                  path ===
                    `/:organizationId/:projectId/:environmentId/backups`) && (
                  <MoreEnvironmentAction
                    onClick={onClickMoreAction}
                    environment={environment}
                  />
                )}
              </React.Fragment>
            )}

            <EnvironmentBackupModal
              isOpen={openBackupModal}
              closeModal={toggleBackupModal}
            />
            {process.env.ENABLE_SOURCE_OPERATION && (
              <SourceOperations
                organizationId={organizationId}
                projectId={projectId}
                environmentId={environmentId}
                isOpen={openSourceOperation}
                onClose={() => setOpenSourceOperation(false)}
              />
            )}
          </ActionButtons>
        )}
      </FloatBox>
    </Wrapper>
  );
};

EnvironmentNavBar.propTypes = {
  project: PropTypes.object,
  environment: PropTypes.object,
  intl: PropTypes.object,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  environmentId: PropTypes.string
};

export default withReducers({
  settingsMenu: () => import("Reducers/settingsMenu")
})(injectIntl(EnvironmentNavBar));
