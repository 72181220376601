import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME = "settingsMenu";

type sliceState = {
  projectPanelExpanded: boolean;
  environmentsPanelExpanded: boolean;
  environmentId: null | string;
};

const initialState: sliceState = {
  projectPanelExpanded: false,
  environmentsPanelExpanded: false,
  environmentId: null
};

type partialGlobalState = {
  [SLICE_NAME]: sliceState;
};

export const settingsMenuSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState as sliceState,
  reducers: {
    toggleProjectPanel: (state: sliceState) => {
      state.projectPanelExpanded = !state.projectPanelExpanded;
    },
    toggleEnvironmentsPanel: (state: sliceState) => {
      state.environmentsPanelExpanded = !state.environmentsPanelExpanded;
    },
    setEnvironmentsContext: (
      state: sliceState,
      action: { type: string; payload: sliceState["environmentId"] }
    ) => {
      state.environmentId = action.payload;
      state.environmentsPanelExpanded = true;
      state.projectPanelExpanded = false;
    },
    setProjectContext: (state: sliceState) => {
      state.environmentId = null;
      state.environmentsPanelExpanded = false;
      state.projectPanelExpanded = true;
    }
  }
});

export const {
  toggleProjectPanel,
  toggleEnvironmentsPanel,
  setProjectContext,
  setEnvironmentsContext
} = settingsMenuSlice.actions;

export const selectProjectPanelExpanded = (state: partialGlobalState) =>
  state.settingsMenu.projectPanelExpanded;
export const selectEnvironmentsPanelExpanded = (state: partialGlobalState) =>
  state.settingsMenu.environmentsPanelExpanded;
export const selectEnvironmentId = (state: partialGlobalState) =>
  state.settingsMenu.environmentId;

export default settingsMenuSlice.reducer;
