import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";
import { getCSSVarString, ICON } from "Libs/themes";

import Portal from "Components/Portal";
import ChevronIcon from "Components/icons/ChevronIcon";
import ListGroup from "Components/ListGroup";
import Label from "Components/fields/Label";

import * as S from "./ActionDropdown.styles";

const ActionDropdown = ({
  ariaLabel,
  children,
  className,
  closeDropDown,
  closeOnContentClick = true,
  extraLabel,
  handlerType = "click",
  icon,
  iconAfter,
  id,
  isOpen: defaultOpen,
  label,
  noPaddingOverride,
  status,
  toggleDropDown,
  tooltipWidth,
  withArrow = true,
  withBorder = true,
  withClose,
  withPortal = false
}) => {
  const innerRef = useRef(null);

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [coords, setCoords] = useState({});

  useLayoutEffect(() => {
    const handleClickOutside = e => {
      if (
        !innerRef.current?.contains(e.target) &&
        e.target.className !== "copy"
      ) {
        if (closeDropDown) {
          closeDropDown();
          return;
        }
        setIsOpen(false);
      }
    };

    if (handlerType === "click" && isOpen)
      document.addEventListener("click", handleClickOutside, { capture: true });

    return () => {
      if (handlerType === "click" && isOpen)
        document.removeEventListener("click", handleClickOutside, {
          capture: true
        });
    };
  }, [isOpen, handlerType, closeDropDown]);

  useLayoutEffect(() => {
    setIsOpen(value => {
      if (defaultOpen !== value) {
        return defaultOpen;
      }
    });
  }, [defaultOpen]);

  const toggleOpen = useCallback(() => {
    setIsOpen(value => !value);
  }, []);

  useEffect(() => {
    if (handlerType !== "click") return;
    if (isOpen) {
      document.addEventListener("scrolled", toggleOpen);
    } else {
      document.removeEventListener("scrolled", toggleOpen);
    }
    return () => {
      if (handlerType === "click")
        document.removeEventListener("scrolled", toggleOpen);
    };
  }, [isOpen, handlerType, toggleOpen]);

  const updateCoords = () => {
    if (!withPortal) return;
    const rect = innerRef.current?.getBoundingClientRect();
    if (!rect) return;
    setCoords({
      left: rect.x + rect.width,
      top: rect.y + window.scrollY + rect.height,
      transform: "translate(-100%, 0)"
    });
  };

  const handleKeyUp = e => {
    if ((e.key === 27 && isOpen) || [13, 40].includes(e.key)) toggleOpen();
  };

  const handleToggle = () => {
    updateCoords();
    if (toggleDropDown) {
      toggleDropDown();
      document.activeElement.blur();
      return;
    }
    toggleOpen();
    document.activeElement.blur();
  };

  const handleClose = () => {
    if (handlerType !== "click") return;
    if (closeOnContentClick) toggleOpen();
  };

  return (
    <S.Wrapper
      id={id}
      ref={innerRef}
      className={`info-button${isOpen ? " open-wrapper" : ""}
      ${className ? " " + className : ""}`}
      onKeyUp={handleKeyUp}
      onMouseEnter={() => handlerType === "hover" && handleToggle()}
      onMouseLeave={() => handlerType === "hover" && handleToggle()}
    >
      <LiveMessage
        message={`${label} popup menu has been ${isOpen ? "opened" : "closed"}`}
        aria-live="polite"
      />

      <S.ButtonToggle
        type="button"
        aria-label={ariaLabel}
        id={`${id}-open`}
        noPaddingOverride={noPaddingOverride}
        withBorder={withBorder}
        onClick={e => {
          e.preventDefault();
          if (status !== "inactive") {
            handleToggle();
          }
        }}
        onKeyUp={handleKeyUp}
        className={`${
          icon && !label ? "info-icon action-dropdown" : "action-dropdown"
        }${isOpen ? " open" : ""}${extraLabel ? " with-label" : ""}`}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {icon && !iconAfter && icon}
        {(extraLabel || label) && (
          <div className="text">
            <span className="active" />
            {extraLabel && <Label>{extraLabel}</Label>}
            {label && label}
          </div>
        )}
        {icon && iconAfter && icon}
        {withArrow && (
          <span className="chevron">
            <ChevronIcon color={"var(--icon-slate-fill,var(--icon-slate,var(--slate)))"} />
          </span>
        )}
      </S.ButtonToggle>

      {isOpen && (
        <Portal parent={withPortal ? null : innerRef.current}>
          <S.Window
            id={`${id}-window`}
            className={`tooltip ${id}-window`}
            open={isOpen}
            style={coords}
            tooltipWidth={tooltipWidth}
            marginTop={handlerType === "hover" ? 0 : 4}
          >
            <ListGroup
              aria-label="tooltip content"
              id="tooltip-content-wrapper"
              className="tooltip-content-wrapper"
            >
              {withClose && <S.CloseWindow onClick={toggleOpen} />}
              <div
                className="tooltip-content"
                onClick={handleClose}
                aria-hidden="true"
              >
                {children}
              </div>
            </ListGroup>
          </S.Window>
        </Portal>
      )}
    </S.Wrapper>
  );
};

ActionDropdown.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  closeDropDown: PropTypes.func,
  closeOnContentClick: PropTypes.bool,
  extraLabel: PropTypes.string,
  handlerType: PropTypes.oneOf(["click", "hover"]),
  icon: PropTypes.node,
  iconAfter: PropTypes.bool,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noPaddingOverride: PropTypes.bool,
  status: PropTypes.string,
  toggleDropDown: PropTypes.func,
  tooltipWidth: PropTypes.number,
  withArrow: PropTypes.bool,
  withBorder: PropTypes.bool,
  withClose: PropTypes.bool,
  withPortal: PropTypes.bool
};

export default ActionDropdown;
