import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";

import ModalWelcome from "Components/ModalWelcome";
import useLocalForage from "Hooks/useLocalForage";
import { withReducers } from "Hocs";
import {
  paymentSourceLoadingSelector,
  paymentSourceSelector,
  getPaymentSource
} from "Reducers/paymentSource";
import {
  organizationPaymentSourceLoadingSelector,
  organizationPaymentSourceSelector,
  getOrganizationPaymentSource
} from "Reducers/organization/paymentSource";
import {
  getOrganizationProfile,
  organizationProfileSelector
} from "Reducers/organization/profile";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

import { hasSafeRole } from "Libs/utils";
import { getIsLoadingState } from "Reducers/utils";

import { PaymentMissing, Trial } from "./";

const BannerManager = ({
  isProjectOwner = true,
  organizationId,
  projects,
  user
}) => {
  const dispatch = useDispatch();
  const useBrandedArt = !process.env.ENABLE_UNBRANDED_ILLUSTRATIONS;
  const currentTime = moment();

  const loadingPaymentSource = useSelector(state => {
    let isLoading = false;
    if (process.env.ENABLE_ORGANIZATION) {
      isLoading = organizationPaymentSourceLoadingSelector(state);
    } else {
      isLoading = paymentSourceLoadingSelector(state);
    }

    return isLoading;
  });

  const hasPaymentSource = useSelector(state => {
    let ps;
    if (process.env.ENABLE_ORGANIZATION) {
      ps = organizationPaymentSourceSelector(state, { organizationId });
    } else {
      ps = paymentSourceSelector(state);
    }
    return !!ps?.type;
  });

  const organizationProfile = useSelector(state =>
    organizationProfileSelector(state, { organizationId })
  );

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );
  const isLoadingProfile = useSelector(state =>
    getIsLoadingState(state.organizationProfile, organizationId)
  );

  useEffect(() => {
    if (
      process.env.ENABLE_ORGANIZATION &&
      organizationId &&
      organization &&
      !organizationProfile
    ) {
      !isLoadingProfile && dispatch(getOrganizationProfile({ organizationId }));
    }
  }, [organizationId, organization]);

  useEffect(() => {
    if (
      process.env.ENABLE_BILLING &&
      !process.env.ENABLE_ORGANIZATION &&
      loadingPaymentSource === "idle"
    )
      dispatch(getPaymentSource());
    else if (
      process.env.ENABLE_BILLING &&
      process.env.ENABLE_ORGANIZATION &&
      loadingPaymentSource === "idle"
    )
      dispatch(getOrganizationPaymentSource());
  }, []);

  // Find all projects owned by current org to determine whether to display trial welcome modal
  const organizationProjects = projects?.filter(project => {
    return project?.organization_id === organization?.data?.id;
  });

  // Modal won't display if project more than 2 days old, or on additional projects
  let projectCreatedDate;
  if (organizationProjects?.size === 1) {
    const currentProject = organizationProjects?.valueSeq()?.toJS()[0];
    projectCreatedDate = currentProject?.created_at;
  }
  const showTrialModal =
    projectCreatedDate &&
    currentTime.diff(moment(projectCreatedDate), "hours") < 48;

  // Browser remembers if user closed modal already
  const [isClosed = false, setIsClosed, , isLoadingModal] = useLocalForage(
    "trial_welcome_modal_closed"
  );

  const handleClose = e => {
    e?.preventDefault();
    setIsClosed(true);
  };

  // If billing is not enabled, we should not show any
  // trial informations
  if (!process.env.ENABLE_BILLING) {
    return null;
  }

  // We avoid showing the banner if we are still fetching
  // a payment source, so we don't flash the banner
  if (loadingPaymentSource) {
    return null;
  }

  if (hasPaymentSource || hasSafeRole(user.roles)) {
    return null;
  }

  const activeTrial =
    (!process.env.ENABLE_ORGANIZATION &&
      user.trial &&
      user.data?.current_trial?.active) ||
    (process.env.ENABLE_ORGANIZATION &&
      organizationProfile?.data?.current_trial?.active);

  // User or organization is on Trial mode
  if (activeTrial) {
    return (
      <>
        {showTrialModal && !isLoadingModal && (
          <ModalWelcome
            closeModal={handleClose}
            currentTime={currentTime}
            isOpen={!isClosed}
            organizationId={organizationId}
            user={organizationProfile?.data || user.data}
          />
        )}
        <Trial
          user={organizationProfile?.data || user.data}
          useBrandedArt={useBrandedArt}
          organizationId={organizationId}
        />
      </>
    );
  }

  if (!activeTrial && !hasPaymentSource && isProjectOwner)
    return <PaymentMissing organizationId={organizationId} />;

  return null;
};

BannerManager.propTypes = {
  isProjectOwner: PropTypes.bool,
  projects: PropTypes.object,
  user: PropTypes.object,
  organizationId: PropTypes.string
};

export default withReducers({
  paymentSource: () => import("Reducers/paymentSource"),
  organizationPaymentSource: () =>
    import("Reducers/organization/paymentSource"),
  organizationProfile: () => import("Reducers/organization/profile")
})(BannerManager);
