import styled from "styled-components";
import Button from "ds/Button";
import { css, getCSSVarString, LINK, MENU_ITEM } from "Libs/themes";

export const ListItem = styled(Button)`
  justify-content: start;
  font-weight: 400;
  font-size: 14px;
  color:var(--link-ebony-color,var(--link-ebony,var(--ebony)));
  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      background: none;
      pointer-events: auto;
      color:var(--link-periwinkle-grey-color,var(--link-periwinkle-grey,var(--periwinkle-grey)));
    `}};
`;

export const List = styled.ul`
  li {
    &:not(:last-child) {
      padding-bottom: 0 !important;
    }
    ${ListItem} {
      width: 100%;
      padding: 0 8px !important;
      &:hover {
        color:var(--more-menu-item-menu-item-color,var(--menu-item-skye-color,var(--menu-item-skye,var(--skye))));
        background: ${({ theme }) =>
          theme.name === "contrast"
            ? "transparent"
            :"var(--menu-item-skye-light-background-color,var(--menu-item-skye-light,var(--skye-light)))"}
    }
  }
`;

export const Wrapper = styled.div`
  #environment-actions-dropdown-window {
    width: max-content;
    .tooltip-content-wrapper {
      padding-top: 11px;
      padding-bottom: 12px;
    }
  }
`;
