import { getCSSVarString, STICKER } from "Libs/themes";
import styled, { css } from "styled-components";

import { Props, Variant, Priority } from "./Sticker.types";

const PRIORITIES = {
  [Priority.information]: {
    color: getCSSVarString(STICKER, "ebony", "color"),
    background: getCSSVarString(STICKER, "skye-light", "background-color"),
    border: getCSSVarString(STICKER, "skye", "border-color")
  },
  [Priority.low]: {
    color: getCSSVarString(STICKER, "ebony", "color"),
    background: getCSSVarString(STICKER, "cosmic-latte", "background-color"),
    border: getCSSVarString(STICKER, "java", "border-color")
  },
  [Priority.normal]: {
    color: getCSSVarString(STICKER, "ebony", "color"),
    background: getCSSVarString(STICKER, "tulip-pedals", "background-color"),
    border: getCSSVarString(STICKER, "gold", "border-color")
  },
  [Priority.critical]: {
    color: getCSSVarString(STICKER, "ebony", "color"),
    background: getCSSVarString(STICKER, "misty-rose", "background-color"),
    border: getCSSVarString(STICKER, "red", "border-color")
  }
};

const dash = (color: string, direction: "bottom" | "right") => `
    repeating-linear-gradient(
      to ${direction},
      ${color} 0%,
      ${color} 35%,
      transparent 35%,
      transparent 55%,
      ${color} 55%,
      ${color} 75%,
      transparent 75%,
      transparent 100%
    )
  `;

const getCustomBorder = (color: string) => css`
  background-image: ${dash(color, "right")}, ${dash(color, "right")},
    ${dash(color, "bottom")}, ${dash(color, "bottom")};
`;

type LayoutProps = Required<Pick<Props, "variant" | "priority">>;

const short = css`
  word-break: break-all;
`;

const button = css`
  display: inline-flex;
  width: 100%;
`;

const long = css`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

export const Layout = styled.div<LayoutProps>`
  display: flex;
  font-family: Open Sans, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  box-sizing: border-box;
  padding: 10px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  background: ${({ priority }) =>
    PRIORITIES[priority || Priority.information].background};
  ${({ priority }) =>
    getCustomBorder(`${PRIORITIES[priority || Priority.information].border}`)};
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 24px 1px, 24px 1px, 1px 24px, 1px 24px;
  color: ${getCSSVarString(STICKER, "ebony", "color")};

  & > svg {
    margin-right: 14px;
    width: 20px;
    height: 20px;
    align-self: center;
  }

  ${({ variant }) => {
    switch (variant) {
      case Variant.button:
        return button;
      case Variant.long:
        return long;
      case Variant.short:
      default:
        return short;
    }
  }}

  b {
    font-weight: 600;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

export const TextContainer = styled.div`
  ${short};
  display: flex;
  align-items: center;
  flex: 1;

  & > a {
    padding-left: 4px;
  }
`;
