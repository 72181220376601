import { fromJS, Map, List } from "immutable";

import { normalize, getOrganizationId, isJson } from "Libs/utils";
import { ORGANIZATION_ID_FIELD } from "Constants/constants";
import logger from "Libs/logger";
import { push as pushAction } from "Reducers/app";
import { setErrorState, setIsLoadingState } from "Reducers/utils";

const LOAD_ORGANIZATION_START = "app/organizations/load_organization_start";
export const LOAD_ORGANIZATION_SUCCESS =
  "app/organizations/load_organization_success";
const LOAD_ORGANIZATION_FAILURE = "app/organizations/load_organization_failure";

const CREATE_ORGANIZATION_START = "app/organizations/create_organization_start";
const CREATE_ORGANIZATION_SUCCESS =
  "app/organizations/create_organization_success";
const CREATE_ORGANIZATION_FAILURE =
  "app/organizations/create_organization_failure";

const UPDATE_ORGANIZATION_START = "app/organizations/update_organization_start";
const UPDATE_ORGANIZATION_SUCCESS =
  "app/organizations/update_organization_success";
const UPDATE_ORGANIZATION_FAILURE =
  "app/organizations/update_organization_failure";

const DELETE_ORGANIZATION_START = "app/organizations/delete_organization_start";
const DELETE_ORGANIZATION_SUCCESS =
  "app/organizations/delete_organization_success";
const DELETE_ORGANIZATION_FAILURE =
  "app/organizations/delete_organization_failure";

const LOAD_ORGANIZATIONS_START = "app/organizations/load_organizations_start";
export const LOAD_ORGANIZATIONS_SUCCESS =
  "app/organizations/load_organizations_success";
export const LOAD_REF_ORGANIZATIONS_SUCCESS =
  "app/organizations/load_more_organizations_success";
const LOAD_ORGANIZATIONS_FAILURE =
  "app/organizations/load_organizations_failure";

const LOAD_ORGANIZATION_MEMBERS_START =
  "app/organizations/load_organization_members_start";
const LOAD_ORGANIZATION_MEMBERS_SUCCESS =
  "app/organizations/load_organization_members_success";
const LOAD_ORGANIZATION_MEMBERS_FAILURE =
  "app/organizations/load_organization_members_failure";

const ADD_ORGANIZATION_MEMBER_START =
  "app/organizations/add_organization_member_start";
const ADD_ORGANIZATION_MEMBER_SUCCESS =
  "app/organizations/add_organization_member_success";
const ADD_ORGANIZATION_MEMBER_FAILURE =
  "app/organizations/add_organization_member_failure";

const DELETE_ORGANIZATION_MEMBER_START =
  "app/organizations/delete_organization_member_start";
const DELETE_ORGANIZATION_MEMBER_SUCCESS =
  "app/organizations/delete_organization_member_success";
const DELETE_ORGANIZATION_MEMBER_FAILURE =
  "app/organizations/delete_organization_member_failure";

const LOAD_ORGANIZATIONS_FAILURE_CLEAR =
  "app/organizations/clear_organization_error";

const CLEAR_CREATED_ORGANIZATION =
  "app/organization/clear_created_organization";

export const CreateOrganizationNamespace = "createOrganization";

export const updateOrganization = (organizationDescriptionId, organization) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_ORGANIZATION_START });

    try {
      const organizationToUpdate = await getOrganization(
        getState,
        organizationDescriptionId
      );

      const newOrganization = await organizationToUpdate.update(
        organization,
        organizationToUpdate.getLink("self")
      );

      dispatch({
        type: UPDATE_ORGANIZATION_SUCCESS,
        payload: organizationToUpdate.updateLocal(newOrganization.data),
        meta: { organizationDescriptionId }
      });

      if (organization?.name) {
        dispatch(
          pushAction({ path: `/${organization?.name}/-/settings`, args: {} })
        );
        window.location.reload();
      }
    } catch (err) {
      logger(err, {
        action: "updateOrganization",
        organizationDescriptionId,
        organization
      });
      dispatch({
        type: UPDATE_ORGANIZATION_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const deleteOrganization = organizationDescriptionId => {
  return async (dispatch, getState) => {
    try {
      const organizationToDelete = await getOrganization(
        getState,
        organizationDescriptionId
      );
      dispatch({
        type: DELETE_ORGANIZATION_START,
        payload: organizationToDelete
      });
      await organizationToDelete.delete();
      dispatch({
        type: DELETE_ORGANIZATION_SUCCESS,
        payload: organizationToDelete
      });
    } catch (err) {
      logger(err, {
        action: "deleteOrganization",
        organizationDescriptionId
      });
      dispatch({
        type: DELETE_ORGANIZATION_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const createOrganization = organization => {
  return async dispatch => {
    dispatch({ type: CREATE_ORGANIZATION_START });
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const newOrganization = await client.createOrganization(organization);
      dispatch({
        type: CREATE_ORGANIZATION_SUCCESS,
        payload: newOrganization
      });

      return newOrganization;
    } catch (err) {
      logger(err, {
        action: CreateOrganizationNamespace,
        organization
      });
      dispatch({
        type: CREATE_ORGANIZATION_FAILURE,
        error: true,
        payload: typeof err === "string" ? JSON.parse(err) : err
      });
    }
  };
};

const getOrganization = async (getState, descriptionId, dispatch) => {
  let organization = getState().organization.getIn([
    "orgByDescriptionField",
    descriptionId
  ]);

  if (!organization) {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    let organizationId = getOrganizationId(getState, descriptionId);

    // For admins, they may not have the organization in their /organizations response
    if (!organizationId) {
      organizationId = `name=${descriptionId}`;
    }

    try {
      const organization = await client.getOrganization(organizationId);
      if (dispatch) {
        dispatch({
          type: LOAD_ORGANIZATION_SUCCESS,
          payload: organization
        });
      }

      return organization;
    } catch (err) {
      return;
    }
  }

  return organization;
};

export const loadOrganizationMembers = descriptionId => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_ORGANIZATION_MEMBERS_START });

    try {
      const organization = await getOrganization(
        getState,
        descriptionId,
        dispatch
      );

      const members = await organization.getMembers();

      dispatch({
        type: LOAD_ORGANIZATION_MEMBERS_SUCCESS,
        payload: members,
        meta: descriptionId
      });
    } catch (err) {
      logger(err, {
        action: CreateOrganizationNamespace,
        descriptionId
      });
      dispatch({
        type: LOAD_ORGANIZATION_MEMBERS_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const deleteOrganizationMember = (descriptionId, memberId) => {
  return async (dispatch, getState) => {
    dispatch({ type: DELETE_ORGANIZATION_MEMBER_START });
    try {
      const member = getState().organization.getIn([
        "data",
        descriptionId,
        "members",
        memberId
      ]);
      await member.delete();

      dispatch({
        type: DELETE_ORGANIZATION_MEMBER_SUCCESS,
        payload: memberId,
        meta: descriptionId
      });
    } catch (err) {
      logger(err, {
        action: "deleteOrganizationMember",
        descriptionId,
        memberId
      });
      dispatch({
        type: DELETE_ORGANIZATION_MEMBER_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const addOrganizationMembers = (descriptionId, member) => {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_ORGANIZATION_MEMBER_START });

    try {
      const organization = await getOrganization(
        getState,
        descriptionId,
        dispatch
      );

      const result = await organization.addMember(member);
      const newMember = await result.getEntity();

      dispatch({
        type: ADD_ORGANIZATION_MEMBER_SUCCESS,
        payload: newMember,
        meta: descriptionId
      });
    } catch (err) {
      logger(err, {
        action: "addOrganizationMembers",
        descriptionId,
        member
      });
      dispatch({
        type: ADD_ORGANIZATION_MEMBER_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const loadOrganization = descriptionId => {
  return async (dispatch, getState) => {
    if (!process.env.ENABLE_ORGANIZATION) {
      return;
    }
    let organization = await getOrganization(getState, descriptionId, dispatch);

    if (organization) {
      return organization;
    }

    dispatch({ type: LOAD_ORGANIZATION_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      let organizationId = getOrganizationId(getState, descriptionId);

      // For admins, they may not have the organization in their /organizations response
      if (!organizationId) {
        organizationId = `name=${descriptionId}`;
      }

      try {
        organization = await client.getOrganization(organizationId);
      } catch (err) {
        throw "organization.notfound";
      }

      dispatch({
        type: LOAD_ORGANIZATION_SUCCESS,
        payload: organization
      });
    } catch (err) {
      if (![404, 403, 400].includes(err.code)) {
        logger(err, {
          action: "loadOrganization",
          id: descriptionId
        });
      }
      dispatch({ type: LOAD_ORGANIZATION_FAILURE, error: true, payload: err });
    }
  };
};

export const loadOrganizations = userId => {
  return async dispatch => {
    if (!process.env.ENABLE_ORGANIZATION) {
      return;
    }

    dispatch({ type: LOAD_ORGANIZATIONS_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const organizations = await client.getOrganizations({ userId });

      dispatch({
        type: LOAD_ORGANIZATIONS_SUCCESS,
        payload: organizations
      });
    } catch (err) {
      logger(err, {
        action: "loadOrganizations"
      });
      dispatch({ type: LOAD_ORGANIZATIONS_FAILURE, error: true, payload: err });
    }
  };
};

export const clearOrginaizationError = () => dispatch => {
  dispatch({ type: LOAD_ORGANIZATIONS_FAILURE_CLEAR });
};

export const clearCreatedOrganization = () => ({
  type: CLEAR_CREATED_ORGANIZATION
});

const setError = (state, action) => {
  let message = "";
  if (isJson(action.payload)) {
    const errors = JSON.parse(action.payload);
    message = errors.detail;
  }

  return state.set("errors", message).set("loading", false);
};

export default function organizationReducer(state = new Map(), action) {
  switch (action.type) {
    case UPDATE_ORGANIZATION_START:
    case LOAD_ORGANIZATION_START:
    case LOAD_ORGANIZATIONS_START:
      return state.set("loading", true);
    case LOAD_REF_ORGANIZATIONS_SUCCESS:
      return state
        .set(
          "orgByDescriptionField",
          state
            .get("orgByDescriptionField", Map())
            .merge(fromJS(normalize(action.payload, ORGANIZATION_ID_FIELD)))
        )
        .set(
          "data",
          state
            .get("data", Map())
            .merge(fromJS(normalize(action.payload, "id")))
        );
    case LOAD_ORGANIZATIONS_SUCCESS:
      return state
        .set("loading", false)
        .set(
          "orgByDescriptionField",
          state
            .get("orgByDescriptionField", Map())
            .merge(fromJS(normalize(action.payload, ORGANIZATION_ID_FIELD)))
        )
        .set(
          "data",
          state
            .get("data", Map())
            .merge(fromJS(normalize(action.payload, "id")))
        )
        .set("list", fromJS(action.payload.map(o => o.id))) // Organization Id the user own or his member of, use in the organizationsMemberOrOwnerOfSelector
        .remove("errors");
    case UPDATE_ORGANIZATION_SUCCESS:
      return state
        .set("loading", false)
        .setIn(["update", action.payload], true);

    case CREATE_ORGANIZATION_FAILURE: {
      const updateLoadingState = setIsLoadingState(
        state,
        CreateOrganizationNamespace,
        false
      );
      return setErrorState(
        updateLoadingState,
        CreateOrganizationNamespace,
        action.payload
      );
    }
    case CREATE_ORGANIZATION_START: {
      const withError = setErrorState(state, CreateOrganizationNamespace);
      return setIsLoadingState(withError, CreateOrganizationNamespace);
    }
    case CREATE_ORGANIZATION_SUCCESS:
      return setIsLoadingState(state, CreateOrganizationNamespace, false).set(
        CreateOrganizationNamespace,
        action.payload
      );
    case CLEAR_CREATED_ORGANIZATION:
      return state.delete(CreateOrganizationNamespace);
    case LOAD_ORGANIZATION_SUCCESS:
      return state
        .set("loading", false)
        .deleteIn([
          "orgByDescriptionField",
          action.meta?.organizationDescriptionId !== action.payload?.name &&
            action.meta?.organizationDescriptionId
        ])
        .setIn(["data", action.payload.id], fromJS(action.payload))
        .setIn(
          ["orgByDescriptionField", action.payload[ORGANIZATION_ID_FIELD]],
          fromJS(action.payload)
        )
        .set("organizationEdit", false)
        .remove("errors");
    case DELETE_ORGANIZATION_MEMBER_SUCCESS:
      return this.state.deleteIn(["members", action.meta, action.payload]);
    case LOAD_ORGANIZATION_MEMBERS_SUCCESS:
      return state.setIn(
        ["members", action.meta],
        fromJS(normalize(action.payload, "user"))
      );
    case ADD_ORGANIZATION_MEMBER_SUCCESS:
      return state.setIn(
        ["members", action.meta, action.payload.user],
        action.payload
      );
    case UPDATE_ORGANIZATION_FAILURE:
    case LOAD_ORGANIZATIONS_FAILURE:
      return setError(state, action);
    case LOAD_ORGANIZATION_FAILURE:
      return state
        .set("loading", false)
        .set("organizationLoadingError", action.payload);
    case LOAD_ORGANIZATIONS_FAILURE_CLEAR:
      return state.delete("organizationLoadingError");
    case DELETE_ORGANIZATION_SUCCESS:
      return state
        .setIn(["deleted", action.payload.id], action.payload)
        .setIn(["deleted", "loading"], false);
    case DELETE_ORGANIZATION_START:
      return state.setIn(["deleted", "loading"], true);
    case DELETE_ORGANIZATION_FAILURE:
      return setError(state, action).setIn(["deleted", "loading"], false);
    default:
      return state;
  }
}

export const organizationSelector = (state, props) =>
  state.organization.getIn(["data", props.organizationId]);

export const organizationByDescriptionIdSelector = (state, props) => {
  return state.organization.getIn([
    "orgByDescriptionField",
    props.organizationDescriptionId
  ]);
};

export const organizationsSelector = state =>
  state.organization.get("data", Map());

export const organizationsMemberOrOwnerOfSelector = state =>
  state.organization
    .get("orgByDescriptionField", List())
    .filter(o => state.organization.get("list")?.find(id => o.id === id));

export const canCreateProjectOrganizationsSelector = state =>
  organizationsMemberOrOwnerOfSelector(state).filter(o =>
    o.hasLink("create-subscription")
  );

export const organizationLoadingSelector = state =>
  state.organization.get("loading");

export const organizationLoadingErrorSelector = state =>
  state.organization.get("organizationLoadingError");

export const organizationErrorsSelector = state =>
  state.organization.get("errors");

export const organizationMemberOrOwnerOfByDescriptionIdSelector = (
  state,
  props
) =>
  state.organization
    .get("orgByDescriptionField", List())
    .filter(o => state.organization.get("list")?.find(id => o.id === id))
    .get(props.organizationDescriptionId);

export const createdOrganizationSelector = state =>
  state.organization.get(CreateOrganizationNamespace);

export const deletedOrganizationSelector = (state, organizationId) =>
  state.organization.getIn(["deleted", organizationId]);

export const isLoadingDeleteOrganizationSelector = state =>
  state.organization.getIn(["deleted", "loading"]);
